import axios from 'axios';

const handleAlarmStatus = (data) => axios.get('/mocha/alarm/handleAlarmStatus',data);//修改报警状态
const findById = (data) => axios.get('/mocha/alarm/findById',data);//获取详情
const list = (data) => axios.get('/show/safe/alarmList',data);//获取报警详情
//工单
const CreateWorkOrder=(data)=>axios.post('/mocha/alarm/handleSheet',data);

const findChildCompany=(data)=>axios.get('/sys/companystructure/findChildCompany', data);
const selectCompanyUser=(data)=>axios.get('/sys/user/selectCompanyUser', data);


export default {
  findById,
  handleAlarmStatus,
  list,

  CreateWorkOrder,
  findChildCompany,
  selectCompanyUser,

}
