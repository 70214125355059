<template>
  <div class="home">
    <myheader></myheader>
    <mymenu></mymenu>
    <mymap></mymap>
    <router-view  />
  </div>
</template>

<script>
import myheader from "@/components/header.vue";
import mymenu from "@/components/menu.vue";
import mymap from "@/components/map.vue";
export default {
  components: {
    myheader,
    mymenu,mymap
  },
  data() {
    return {
      globalDate:{},
    };
  },
  methods:{
    // Cdate(v){
    //   this.globalDate = v
    // }  
  }

};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
