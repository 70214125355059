<template>
  <div class="menu">
    <a @click="tolink(item)" href="javascript:;" v-for="(item,index) in menulist" :key="index" :class="active==index?'active':''">{{item.name}}</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menulist:[
        {id:0,name:'首 页',href:'/'},
        {id:1,name:'能源管理',href:'/energy'},
        {id:2,name:'智能运维',href:'/intelligence'},
        {id:3,name:'安全管理',href:'/security'},
        {id:4,name:'节能减排',href:'/reduce'},
      ],
      active:0,
    };
  },
  mounted(){
    if(localStorage.getItem('navPage')){
      this.active = localStorage.getItem('navPage')
    }
  },
  methods:{
    tolink(row){
      localStorage.setItem('navPage',row.id)
      this.active = row.id
      this.$router.push(row.href)
    }
  }
}
</script>

<style lang="less" scoped>
.menu{ position: absolute; z-index: 9; bottom: 0; width: 2298px; left: 50%; margin-left: -1149px; height: 115px; background: url(../assets/images/menubg.png) bottom center no-repeat;
  display: flex; flex-direction: row; justify-content: space-between; padding:0 220px;
  a{ display: inline-block; font-size: 1.6rem; background: #07188a; border: #406ffe solid 1px; height: 88px; width: 300px; text-align: center; line-height: 82px; cursor: pointer;}
  a:hover{ color: #fff; box-shadow: 0 0 20px rgba(255,255,255,.2) inset;}
  a.active{ color: #fff; background-image: linear-gradient(#3c62fe, #0b1eb6);}
}
</style>
