import axios from 'axios';

export default{
  //查询签了智能运维合同的单位
  getMindContract(id) {
    return axios.get('/mocha/capital/getMindContract?companyId='+id);
  },
  //查询所有企业
  getAllCompany() {
    return axios.get('/custom/getAll');
  },
  //通过部门ID查找部门下的所有人
  getMindUser(id) {
    return axios.get('/custom/device/ops/getMindUser?companyStructId='+id);
  },
  //获取本企业所有用户
  getAllUser(id) {
    return axios.get('/custom/users/findCompanyUser?companyId='+id);
  },
  //删除图片 传真实路径 (dycn/)
  delUploadImg(path){
    return axios.delete('/oos/del?filePath='+path.filePath);
  },
  //通过token 查找企业区域，及区域下面的设备,及设备关联的采集器
  findCapitalAreas(id){
    return axios.get('/mocha/capital/findSasAreasByCompanyId?companyId='+id)
  },


  //  设备管理 - 基本信息
  //设备管理-获取设备模型树
  getModuleInfo() {
    return axios.get('/mocha/capital/info');
  },
  //获取设备模型特有信息
  getModuleList(id) {
    return axios.get('/mocha/capital/property/list?modeId='+id);
  },
  //保存设备-新增
  saveDeviceInfo(data) {
    return axios.post('/mocha/capital/addCapitalInfo',data);
  },
  //设备管理-获取设备列表
  getDeviceList(data) {
    return axios.get('/mocha/capital/list?modeId='+data.id+'&limit='+data.limit+'&page='+data.page+'&name='+data.name+'&companyId='+data.companyId);
  },
  //设备管理-获取设备详情/mocha/capital/info/{id}
  getDeviceDetails(id) {
    return axios.get('/mocha/capital/info/'+id)
  },
  //设备管理-删除设备
  deleteDevice(data) {
    return axios.delete('/mocha/capital/delCapitalInfo?id='+data)
  },
  //设备管理-编辑修改设备
  updateDevice(data) {
    return axios.put('/mocha/capital/updateCapitalInfo',data)
  },
  //设备管理-查看当前设备是否是生产设备
  isProduction(id) {
    return axios.get('/mocha/capital/isProduction?id='+id)
  },
  
  //设备管理-能耗信息
  //保存能耗信息
  saveDevicePower(data) {
    return axios.post('/mocha/capital/energy/save',data)
  },
  //获取能耗信息详情
  getDevicePower(id) {
    return axios.get('/mocha/capital/energy/info/'+id)
  },
  //设备管理-编辑修改设备
  updateDevicePower(data) {
    return axios.put('/mocha/capital/energy/update',data)
  },
  
  
  //设备管理-运维信息
  //保存运维信息
  saveOpsMsg(data) {
    return axios.post('/mocha/capital/ops/save',data)
  },
  //获取运维信息列表
  getOpsList(data) {
    return axios.get('/mocha/capital/ops/list?page='+data.page+'&limit='+data.limit+'&devId='+data.devId+'&companyId='+data.companyId)
  },
  //获取运维信息详情
  getOpsDetails(id) {
    return axios.get('/mocha/capital/ops/info/'+id)
  },
  //修改运维信息
  updateOpsDetails(data) {
    return axios.put('/mocha/capital/ops/update',data)
  },
  //修改运维信息
  delOpsDetails(data) {
    return axios.post('/mocha/capital/ops/delete',data)
  },

  //设备管理-运维信息
  //获取表计信息列表
  getRelationList(id) {
    return axios.get('/mocha/capital/delete?devId='+id)
  },

  //产线管理-运行记录
  //获取运行记录下面部分列表
  getRunIngList(data) {
    return axios.get('/mocha/device/runIng/list?productDevId='+data.id+'&page='+data.page+'&limit='+data.limit)
  },
  //获取运行记录上面部分数据
  getProdoctDeviceStatus(data) {
    return axios.get('/mocha/device/runIng/getProdoctDeviceStatus?productDevId='+data.id)
  },
  //获取运行记录上面部分折线图
  getProdoctDeviceData(data) {
    return axios.get('/mocha/device/runIng/getProdoctDeviceData?productDevId='+data.id+'&type=1')
  },
}