<template>
  <div style="width: 100%;height: 100%;" class="AMap" id="container">
    <!-- <div id="myPageTop">
      <table>
        <tr>
          <td>
            <label>请输入关键字：</label>
          </td>
        </tr>
        <tr>
          <td>
            <input autocomplete="off" id="tipinput" />
          </td>
        </tr>
      </table>
    </div> 
    -->
  </div>
</template>

<script>
import remoteLoad from '@/utils/remoteLoad'
export default {
  data() {
    return {
      AutoNaviMap: null,

      this: [],
    }
  },
  watch: {},
  //DOM树之前触发 异步加载有问题  index 全局加载
  async created() {
    // 高德地图API判断，已加载初始化地图
    if (window.AMap && window.AMapUI) {
      this.mapInit('container')
    } else {
      // 未载入高德地图API，则先载入API再初始化
      // await remoteLoad(`//cache.amap.com/lbs/static/es5.min.js`)
      await remoteLoad(
       `//webapi.amap.com/maps?v=1.4.15&key=2cbb0fe7670495938085430e3825062a&plugin=AMap.MarkerClusterer,AMap.Geocoder,AMap.DistrictSearch`
      )
      await remoteLoad(
       `//webapi.amap.com/ui/1.0/main.js?v=1.0.11`
      )
        // `//webapi.amap.com/maps?v=1.4.15&key=2cbb0fe7670495938085430e3825062a&plugin=AMap.MarkerClusterer,AMap.Geocoder,AMap.Autocomplete,AMap.PlaceSearch,AMap.CitySearch`
      this.mapInit('container')
    }
  },

  mounted() {
    // this.mapInit('container');
  },
  methods: {
    mapInit(domName) {
      this.AutoNaviMap = new window.AMap.Map(domName, {
        resizeEnable: true, //是否监控地图容器尺寸变化
        center: [104.404739, 31.093686], //初始化地图中心点  //如果center属性缺省，地图默认定位到用户所在城市的中心
        mapStyle: 'amap://styles/79119bc8efdedfee808f56731a684a65',  
        zoom: 12, //初始化地图层级
      })

      // 如center属性缺省 使用ip定位城市
      // 实例化城市查询类
      setTimeout(()=>{
        // var citysearch = new window.AMap.CitySearch();
        // //自动获取用户IP，返回当前城市
        // citysearch.getLocalCity( (status, result) =>{
        //   if (status === 'complete' && result.info === 'OK') {
        //     if (result && result.city && result.bounds) {
        //       var cityinfo = result.city;
        //       var citybounds = result.bounds;
        //       console.log('您当前所在城市：' + cityinfo,citybounds);
        //       //地图显示当前城市
        //       this.AutoNaviMap.setBounds(citybounds);
        //     }
        //   } else {
        //     console.log(result.info);
        //   }
        // });


        // //搜索输入提示
        // var autoOptions = {
        //   input: "tipinput"
        // };
        //  new window.AMap.Autocomplete(autoOptions);
        //  new window.AMap.PlaceSearch({
        //   map: this.AutoNaviMap
        // });  //构造地点查询类
        // // window.AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发
        // // function select(e) {
        // //   console.log('e1545',e);
        // //   placeSearch.setCity(e.poi.adcode);
        // //   placeSearch.search(e.poi.name);  //关键字查询查询
        // // }


      },1000)

      // 结

      // // 同时引入工具条插件，比例尺插件和鹰眼插件
      // window.AMap.plugin([
      //   'AMap.ToolBar',
      //   'AMap.Geolocation',
      // ], ()=>{
      //   // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
      //   this.AutoNaviMap.addControl(new window.AMap.ToolBar());
      //   // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
      //   this.AutoNaviMap.addControl(new window.AMap.Geolocation());
      // });

      // this.handlePolygon('双流')//勾勒地区



      this.$emit('amapDone') //完成加载 通知父组件
    },
    //点击地图选点
    setpoint(){
      var aa,marker = null
      //为地图注册click事件获取鼠标点击出的经纬度坐标
      this.AutoNaviMap.on('click', (e) => {
        var longitude = e.lnglat.getLng()
        var latitude = e.lnglat.getLat()
        var coordinate = e.lnglat.getLng() + ',' + e.lnglat.getLat()
        // console.log(longitude, latitude, coordinate)
        // $("#longitude").val(longitude);
        // $("#latitude").val(latitude);
        // $("#coordinate").val(coordinate);

        marker = new window.AMap.Marker({
          icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          // icon: require("../assets/images/mark_r.png") ,
          position: [longitude, latitude],
          // offset: new AMap.Pixel(-13, -30),
          // title: coordinate[i].name,
          // map: map
        })
        if (aa) {
          this.AutoNaviMap.remove(aa)
        }
        aa = marker
        marker.setMap(this.AutoNaviMap)
 
        this.regeoCode(coordinate)
      })
    },






    //通过经纬度查询地址
    regeoCode(lnglat) {
      var geocoder = new window.AMap.Geocoder({
        // city: "010", //城市设为北京，默认：“全国”
        // radius: 1000 //范围，默认：500
      })
      geocoder.getAddress(lnglat,  (status, result) =>{
        if (status === 'complete' && result.regeocode) {
          var address = result.regeocode.formattedAddress
          // console.log(address);
          // $('#md_addr').val(address)
          this.$emit('site',[lnglat,address])//传给父组件
        } else {
          console.error('根据经纬度查询地址失败')
        }
        
      })
    },

    myclearMap() {
      // 清除地图上所有添加的覆盖物
      this.AutoNaviMap.clearMap()
    },
    //设置地图当前行政区
    setCity(city) {
      this.AutoNaviAMap.setCity(city)
    },
    //同时设置地图层级与中心点
    setZoomAndCenter() {
      this.AutoNaviAMap.setZoomAndCenter(9, [104.417061, 31.143335])
    },
    // 覆盖区域
    coverAreas(city) {
      var that = this
      // if (that.polygon) {
      //   that.AutoNaviAMap.remove(that.polygon)
      // }
      window.AMap.plugin('AMap.DistrictSearch', function () {
        new window.AMap.DistrictSearch({
          extensions: 'all',
          subdistrict: 0,
        }).search(city, function (status, result) {
          if (status == 'complete') {
            // 外多边形坐标数组和内多边形坐标数组
            var outer = [
              new window.AMap.LngLat(-360, 90, true),
              new window.AMap.LngLat(-360, -90, true),
              new window.AMap.LngLat(360, -90, true),
              new window.AMap.LngLat(360, 90, true),
            ]
            var holes = result.districtList[0].boundaries
            var pathArray = [outer]
            pathArray.push.apply(pathArray, holes)
            that.polygon = new window.AMap.Polygon({
              pathL: pathArray,
              strokeColor: '#88836F', //城市边界颜色
              strokeWeight: 2,
              fillColor: 'rgba(11, 45, 69, 0.8)', // 遮罩背景色颜色
              fillOpacity: 1,
            })
            that.polygon.setPath(pathArray)
            that.AutoNaviAMap.add(that.polygon)
            // // 地图自适应
            // this.AutoNaviAMap.setFitView()
          }
        })
      })
    },
    //画行政区边界
    handlePolygon(area) {
      this.$nextTick(() => {
        //获取边界坐标点
        window.AMap.plugin('AMap.DistrictSearch', () => {
          var districtSearch = new window.AMap.DistrictSearch({
            level: 'province', // 关键字对应的行政区级别，共有5种级别
            subdistrict: 0, //  是否显示下级行政区级数，1表示返回下一级行政区
            extensions: 'all', // 返回行政区边界坐标点
          })
          // 搜索所有省/直辖市信息
          districtSearch.search(area, (status, result) => {
            // 查询成功时，result即为对应的行政区信息
            if (status == 'complete') {
              //画轮廓
              let bounds = result.districtList[0].boundaries
              if (bounds) {
                for (let i = 0, l = bounds.length; i < l; i++) {
                  //生成行政区划polygon
                  let polygon = new window.AMap.Polygon({
                    map: this.AutoNaviMap, // 指定地图对象
                    strokeWeight: 1, // 轮廓线宽度
                    path: bounds[i], //轮廓线的节点坐标数组
                    fillOpacity: 0.15, //透明度
                    fillColor: '#256edc', //填充颜色
                    strokeColor: '#256edc', //线条颜色
                  })
                  polygon.on('click', (e) => {
                    // 点击绘制的区域时执行其他交互
                    // ......
                    console.log(e)
                  })
                }
                // 地图自适应
                this.AutoNaviMap.setFitView()
              }
            }
          })
        })
      })
    },

    
    //批量标点
    punctuationArr(arr) {

    //加载地图优化相关组件
    // window.AMapUI.load(['ui/geo/DistrictCluster', 'ui/misc/PointSimplifier', 'lib/$'], (DistrictCluster, PointSimplifier)=> {

    //     var pointSimplifierIns = new PointSimplifier({
    //         map: this.AutoNaviMap, //所属的地图实例
    //         autoSetFitView: true, //禁止自动更新地图视野
    //         zIndex: 110,
    //         getPosition: function(item) {

    //             if (!item) {
    //                 return null;
    //             }

    //             var parts = item.split(',');
    //             //返回经纬度
    //             return [parseFloat(parts[0]), parseFloat(parts[1])];
    //         },
    //         getHoverTitle: function(dataItem) {
    //             return dataItem;
    //         },
    //         renderOptions: {
    //             //点的样式
    //             pointStyle: {
    //                 width: 6,
    //                 height: 6,
    //                 fillStyle:'rgba(153, 0, 153, 0.38)'
    //             },
    //             //鼠标hover时的title信息
    //             hoverTitleStyle: {
    //                 position: 'top'
    //             }
    //         }
    //     });

    //     var distCluster = new DistrictCluster({
    //         zIndex: 100,
    //         map: this.AutoNaviMap, //所属的地图实例

    //         getPosition: function(item) {

    //             if (!item) {
    //                 return null;
    //             }

    //             var parts = item.split(',');

    //             //返回经纬度
    //             return [parseFloat(parts[0]), parseFloat(parts[1])];
    //         }
    //     });

    //     window.distCluster = distCluster;
    //     let _that = this
    //     function refresh() {

    //         // var zoom = this.AutoNaviMap.getZoom();
            
    //         //获取 pointStyle
    //         var pointStyle = pointSimplifierIns.getRenderOptions().pointStyle;

    //         //根据当前zoom调整点的尺寸
    //         pointStyle.width = pointStyle.height = 2 * Math.pow(1.2, _that.AutoNaviMap.getZoom() - 3);

    //         // var zoom = map.getZoom();

    //         // if (zoom < 10) {

    //         //     pointSimplifierIns.hide();

    //         // } else {

    //         //     pointSimplifierIns.show();
    //         // }
    //     }

    //     this.AutoNaviMap.on('zoomend', function() {
    //         refresh();
    //     });

    //     refresh();

    //     // $('<div id="loadingTip">加载数据，请稍候...</div>').appendTo(document.body);
    //     // $.get('https://a.amap.com/amap-ui/static/data/10w.txt', function(csv) {
    //     //     // $('#loadingTip').remove();
    //     //     var data = csv.split('\n');
    //     //     console.log(data);
    //         let _arrT = []
    //         for(var i in _data){
    //           if(!_data[i].longitude||!_data[i].latitude){
    //             continue;
    //           }
    //           let _str = _data[i].longitude+','+_data[i].latitude
    //           _arrT.push(_str)
    //         }
    //         // console.log(_data);
    //         distCluster.setData(_arrT);

    //         pointSimplifierIns.setData(_arrT);
    //     // });
    //   });

      let _data = arr.mapData
      // 创建一个 Marker 实例：
      // var marker = new AMap.Marker({
      //     position: new AMap.LngLat(31.099796,104.411361),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //     title: '北京'
      // });

      // 将创建的点标记添加到已有的地图实例：
      // this.AutoNaviMap.add(marker);

      // 移除已创建的 marker
      // map.remove(marker);
      let _icon = ''
      switch (arr.type) {
        case 1:
          _icon = require('@/assets/images/mapico/enterprise.png')
          break;
        case 2:
          _icon = require('@/assets/images/mapico/device.png')
          break;
        case 3:
          _icon = require('@/assets/images/mapico/person.png')
          break;
        case 5:
          _icon = require('@/assets/images/mapico/alarm.png')
          break; 
        default:
          break;
      }
      var icon = new window.AMap.Icon({
        size: new window.AMap.Size(50, 50), // 图标尺寸
        // image:  '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png', // Icon的图像
        image:  _icon, // Icon的图像
        imageSize: new window.AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
      })
      var markers = []

      for (const iterator of _data) {
        if (iterator.longitude != '' && iterator.latitude !== null) {
          //标点
          let marker = new window.AMap.Marker({
            icon: icon,
            position: [iterator.longitude, iterator.latitude],
            offset: new window.AMap.Pixel(-13, -30),
            title: iterator.companyName,
            companyId: iterator.companyId,
            clickable: true,
          })

          // 窗体信息
          let infoWindow = new window.AMap.InfoWindow({
            content: `<h5  style = 'color:#3d93fd;padding: 10px;'>`+iterator.name+`</h5>`, //使用默认信息窗体框样式，显示信息内容
            offset: new window.AMap.Pixel(0, -20),
          })

          //给标记点添加点击事件
          marker.on('click', () => {
            //给每一个点位增加点击事件
            let objs = {
              styles:arr.type,
              values:iterator,
            }
            this.$emit('emitClick',objs)
            
            //关闭窗口
            infoWindow.close(
              this.AutoNaviMap,
              // 窗口信息的位置
              marker.getPosition(iterator.longitude, iterator.latitude)
            )

          })
          //给标记点添加移入事件
          marker.on('mouseover', () => {
            //打开窗口
            infoWindow.open(
              this.AutoNaviMap,
              // 窗口信息的位置
              marker.getPosition(iterator.longitude, iterator.latitude)
            )
          })
          //给标记点添加移出事件
          marker.on('mouseout', () => {
            //关闭窗口
            infoWindow.close(
              this.AutoNaviMap,
              // 窗口信息的位置
              marker.getPosition(iterator.longitude, iterator.latitude)
            )

          })

          // AMap.event.addListener(marker, 'click', (e)=>{
          //     console.log("1111",e)
          // });

          // 添加监听事件，关闭信息窗体
          window.AMap.event.addListener(this.AutoNaviMap, 'zoomend', () => {
            // 关闭信息窗体
            this.AutoNaviMap.clearInfoWindow(infoWindow)
          })
          markers.push(marker)
  
        }
      }

      if (this.cluster) {
        this.cluster.setMap(null)
      }

      this.AutoNaviMap.plugin(["AMap.MarkerClusterer"],()=> {

        switch (arr.type) {
          case 5:
            this.cluster = new window.AMap.MarkerClusterer(this.AutoNaviMap, markers, {
              gridSize: 80,
            });
            break;
          default:
            var sts = [{
              url: "https://a.amap.com/jsapi_demos/static/images/blue.png", //聚合量在1-10
              size: new window.AMap.Size(32, 32),
              offset: new window.AMap.Pixel(-16, -16)
            }, {
              url: "https://a.amap.com/jsapi_demos/static/images/green.png", //聚合量在11-100
              size: new window.AMap.Size(32, 32),
              offset: new window.AMap.Pixel(-16, -16)
            }, {
              url: "https://a.amap.com/jsapi_demos/static/images/orange.png", //聚合量在101-1000
              size: new window.AMap.Size(36, 36),
              offset: new window.AMap.Pixel(-18, -18)
            }, {
              url: "https://a.amap.com/jsapi_demos/static/images/red.png", //聚合量在1001-10000
              size: new window.AMap.Size(48, 48),
              offset: new window.AMap.Pixel(-24, -24)
            }, {
              url: "https://a.amap.com/jsapi_demos/static/images/darkRed.png", //聚合量在10001-100000
              size: new window.AMap.Size(48, 48),
              offset: new window.AMap.Pixel(-24, -24)
            }];
            this.cluster = new window.AMap.MarkerClusterer(this.AutoNaviMap, markers, {
              styles: sts,
              gridSize: 80
            });
            break;
        }




        



      })








      
      this.cluster.on('click',(e)=>{
        let getZoom = this.AutoNaviMap.getZoom()
        if(e.markers.length>=2&&getZoom>16){
          // let name = e.markers[0].w.title
          let companyId = e.markers[0].w.companyId
          let objs = {
            styles:arr.type,
            values:companyId,
          }
          this.$emit('emitClick',objs)
          // console.log(name,companyId);
          // console.log(e);
          return
        }
      })
      // markers.setMap(this.AutoNaviMap);
      // 地图自适应
      // this.AutoNaviMap.setFitView()
    },
    //单个点标记
    punctuation(arr=[104.256859,31.180339]) {

      var icon = new window.AMap.Icon({
        size: new window.AMap.Size(40, 50), // 图标尺寸
        image:   '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png', // Icon的图像
        // image: require("../assets/images/mark_r.png") ,
        imageSize: new window.AMap.Size(30, 40), // 根据所设置的大小拉伸或压缩图片
      })      
      // 创建一个 Marker 实例：
      var marker = new window.AMap.Marker({
        icon: icon,
        position: arr,   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '德阳'
      });

      this.AutoNaviMap.setCenter(arr); //设置地图中心点

      // 将创建的点标记添加到已有的地图实例：
      this.AutoNaviMap.add(marker);



      // 移除已创建的 marker
      // map.remove(marker);
      // markers.setMap(this.AutoNaviMap);
    },    
  },
}
</script>

<style lang="less" scoped>
.AMap{

  position: relative; z-index: 3;

  #myPageTop {
    position: absolute;
    z-index: 500;
    top: 5px;
    right: 10px;
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    margin: 10px auto;
    padding: 6px;
    font-family: "Microsoft Yahei", "微软雅黑", "Pinghei";
    font-size: 14px;
  }

  #myPageTop label {
    margin: 0 20px 0 0;
    color: #666666;
    font-weight: normal;
  }

  #myPageTop input {
    width: 170px;
  }

  #myPageTop .column2 {
    padding-left: 25px;
  }
}

</style>
