<!-- 车辆信息 -->
<template>
  <div class="carmessage">
    <div class="subtitle">基本信息</div>
    <el-row :gutter="30">
      <el-form label-width="200px">
        <el-col :span="8">
          <el-form-item label="车牌编号">川F245A1</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车辆品牌">五菱</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车辆型号">XD254-F9</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="定位设备编号">DW32015</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车辆类型">面包车</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="座位">7座</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="负责人">刘平宽（13888888888）</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">闲置</el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      
    }
  },
  
  
  mounted() {
    
  },

  methods: {
   

  },
}
</script>
<style lang='less' scoped>
.carmessage{ width: 100%; height: 100%; height: 60rem; overflow: hidden;
  .el-form-item{ margin-bottom: 4px; border-bottom: rgba(0, 255, 240, .2) solid 1px;
    .el-form-item__label{ text-align: center; padding:0;}
    .el-form-item__content{ text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
  }
}
</style>