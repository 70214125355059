//数据录入

import axios from 'axios';
//查找所有客户 不分页
const clientlist = (data) => axios.get('/custom/selectAllCompany', data);
//查找下属所有公司
const findChildCompany = (data) => axios.get('sys/companystructure/findChildCompany', data);
//查询国网用户编号用水编号用气编号
const getTransform = (data) => axios.get('/sys/custom/energy/getTransform', data);

//通过企业ID查询区域树
const getAreaIdName = (data) => axios.post('/custom/area/getAreaIdName', data);
//通过公司ID 查询所有区域，及区域下的生产设备
const treeMap = (data) => axios.get('/sys/common/info', data);




//能耗填报
//查询年份数据和月份数据
const energyList = (data) => axios.get('/custom/energy/energyList', data);
//按年新增数据填报
const energyFill = (data) => axios.post('/custom/energy/energyFill', data);
//修改数据填报
const energyUpdate = (data) => axios.post('/custom/energy/energyUpdate', data);
//删除年份数据/月份数据
const delEnergy = (data) => axios.delete('/custom/energy/delEnergy', data);



//产能填报
//产品设定
const updateProduct = (data) => axios.post('/custom/energy/updateProduct', data);//修改
const saveProduct = (data) => axios.post('/custom/energy/saveProduct', data);//新增
const productDel = (data) => axios.delete('/custom/energy/productDel', data);//删除
const productList = (data) => axios.get('/custom/energy/productList', data);//列表
//产能填报
const capacityFill = (data) => axios.post('/custom/energy/capacityFill', data);//新增
const capacityList = (data) => axios.get('/custom/energy/capacityList', data);//列表
const capacityUpdate = (data) => axios.post('/custom/energy/capacityUpdate', data);//修改
const capacityDel = (data) => axios.delete('/custom/energy/capacityDel', data);//删除
//排放填报

//废弃物设定
const wasteFill = (data) => axios.post('/custom/waste/wasteFill', data);//新增
const wasteList = (data) => axios.get('/custom/waste/wasteList', data);//列表
const wasteUpdate = (data) => axios.post('/custom/waste/wasteUpdate', data);//修改
const wasteDel = (data) => axios.get('/custom/waste/wasteDel', data);//删除

//排放填报
const dischargeFill = (data) => axios.post('/custom/energy/dischargeFill', data);//新增
const dischargeList = (data) => axios.get('/custom/energy/dischargeList', data);//列表
const dischargeUpdate = (data) => axios.post('/custom/energy/dischargeUpdate', data);//修改
const dischargeDel = (data) => axios.delete('/custom/energy/dischargeDel', data);//删除


//发票录入
const invoiceadd = (data) => axios.post('/custom/invoice/add', data);//新增
const invoicelist = (data) => axios.get('/custom/invoice/list', data);//列表
const invoiceupdate = (data) => axios.put('/custom/invoice/update', data);//修改
const invoicedel = (data) => axios.delete('/custom/invoice/del', data);//删除

export default {
  treeMap,
    clientlist,
    findChildCompany,
    getTransform,
    energyUpdate,
    delEnergy,
    energyFill,
    getAreaIdName,
    energyList,
    saveProduct,
    productDel,
    updateProduct,
    productList,
    capacityFill ,
    capacityUpdate,
    capacityList ,
    capacityDel ,

    dischargeFill ,
    dischargeList ,
    dischargeUpdate,
    dischargeDel ,

    wasteFill ,
    wasteList ,
    wasteUpdate ,
    wasteDel ,
    
    invoiceadd ,
    invoicelist ,
    invoiceupdate ,
    invoicedel ,


}       