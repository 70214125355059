<template>
  <div class="map">
    <!-- <img src="../assets/images/map.png"> -->
    <AMap   ref="AMap" @amapDone="amapDone" @emitClick="emitClick"   v-loading="loading"
    class="loadClass"
    element-loading-text="数据加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"   />

    <div class="typelist">
      <li :class="active==item.id?'active':''" v-for="(item,index) in typelist" :key="index" @click="typeck(item.id)"><i></i>{{item.name}}</li>
    </div>

    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      title="企业信息" 
      :visible.sync="enterprise_dlg" 
      width="50%"
    >
      <div class="enterprise_dlg">
        <div class="subtitle">基本信息</div>
        <el-row :gutter="30">
          <el-form label-width="200px">
            <el-col :span="8">
              <el-form-item label="企业名称">{{enterData.name}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否重点用能企业">{{enterData.energy?'是':'否'}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属行业">{{enterData.tradeJson}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业类型">{{enterpisetype[enterData.type]}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细地址">{{enterData.address}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业规模">{{sizeType[enterData.scope]}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业属性">{{enterData.companyValue}}</el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div class="subtitle" style="margin-top:3rem">企业联系人</div>
        <el-row :gutter="30">
          <el-form v-if="!enterData.linkUser">
            <el-col :span="24" style="text-align:center;">
              暂无联系人
            </el-col>
          </el-form>
          <el-form v-else label-width="200px" v-for="(item,index) in enterData.linkUser" :key="index">
            <el-col :span="4">
              <el-form-item label="联系人姓名">{{item.name}}</el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="联系电话">{{item.telNumber}}</el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="部门">{{item.bumen}}</el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="职务">{{item.zhiwu}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="备注">{{item.mess}}</el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div class="subtitle" style="margin-top:3rem">业务联系人</div>
        <el-row :gutter="30">
          <el-form label-width="200px">
            <el-col :span="8">
              <el-form-item label="运营单位">{{enterData.operateCompanyName?enterData.operateCompanyName:'无'}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="业务负责人">{{enterData.vocaName?enterData.vocaName:'无'}}</el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="负责人电话">13888888888</el-form-item>
            </el-col> -->
          </el-form>
        </el-row>
        <div class="subtitle" style="margin-top:3rem">企业结构</div>
        <el-row :gutter="30">
          <el-form label-width="200px">
            <el-col :span="12">
              <el-form-item label="上属单位">{{enterData.parentId}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下属单位">{{enterData.children}}</el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>    
    <el-dialog
       v-dialogDrag
      title="设备信息" 
      :visible.sync="productLine" 
      width="50%"
      :close-on-click-modal="false"
     >
     <productLine :companyDevID="companyDevID"></productLine>
    </el-dialog> 
    <el-dialog title="车辆信息" :visible.sync="carmessage" width="50%">
     <carMessage></carMessage>
    </el-dialog>

    <!-- 人员 -->
    <userMessage ref="userMessage" />
    <!-- 报警 -->
    <abnormalMessage ref="abnormalMessage" />

  </div>
</template>

<script>
import api from "@/apis/maxMap/map";
import AMap from "./AMap.vue";
import productLine from "./device/deviceSetup.vue";
import userMessage from "./maxMap/UserMessage.vue";
import carMessage from "./CarMessage.vue";
import abnormalMessage from "./maxMap/AbnormalMessage.vue";

import devmap from '@/apis/enDevMap' 
export default {
  components: {
    AMap,productLine,userMessage,carMessage,abnormalMessage
  },
  data() {
    return {
      //企业数据
      enterData:'',
      enterpisetype:['私营企业','国营企业','外资企业','合资企业','独资企业','全名所有制企业','集体所有制企业','股份制企业','有限责任制企业'],
      sizeType:['1-20人','21-50人','51-100人','101-200人','201-500人','501-1000人','1000人以上'],
      companyTypes: ['规上企业','小微企业','高新企业','科技企业','联合实验室','环境评估'],
      //设备数据
      companyDevID:'',
      
      
      
      
      
      //切换tab
      typelist:[
        {id:1,name:'企业'},
        {id:2,name:'设备'},
        {id:3,name:'人员'},
        // {id:4,name:'车辆'},
        {id:5,name:'异常'},
      ],
      active:1,
      loading:false,
      enterprise_dlg:false,
      productLine:false,
      carmessage:false,

    };
  },
  methods:{
    typeck(index){
      this.active=index;
      this.getMap()

    },
    //获取数据
    getMap(){
      this.loading = true
      api.getMap({
        params:{
          type:this.active
        }
      }).then(res=>{
        if (res.code===10000) {
          this.loading = false
          if (this.active==5) {
            for (const iterator of res.data) {
              iterator.longitude = iterator.lng
              iterator.name = iterator.subDeviceName+ '/' +iterator.description
              iterator.latitude  = iterator.lat
            }
          }
          if (this.active==2) {
            for (const iterator of res.data) {
              iterator.longitude = iterator.devLongitude?iterator.devLongitude:iterator.companyLongitude
              iterator.latitude  = iterator.devLatitude?iterator.devLatitude:iterator.companyLatitude
            }
          }
   

          let obj = {
            type:this.active,
            mapData:res.data
          }
          this.$nextTick(()=>{
            this.$refs.AMap.punctuationArr(obj)
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //初始化
    amapDone() {
      // console.log('Amap加载完成')
      this.getMap()
    }, 
    //传回来的数据
    emitClick(row){
      // console.log(row.values);
      switch (row.styles) {
        case 1:
          this.enterprise_dlg = true;
          devmap.selectCustomBasics(row.values.id).then(res=>{
            if(res.code==10000){
              if(res.data&&JSON.stringify(res.data) != "{}"){
                let _data = res.data
                this.enterData = _data
                this.enterData['linkUser'] = _data.linkUser?JSON.parse(_data.linkUser):''
                this.enterData['parentId'] = _data.parentId?JSON.parse(_data.parentId).join():'无'
                this.enterData['children'] = _data.children?JSON.parse(_data.children).join():'无'
                if(!_data.companyValue){
                  this.enterData["companyValue"] = ''
                }else{
                  let _cVal =  []
                  for(var i in _data.companyValue.split('，')){
                    _cVal.push(this.companyTypes[_data.companyValue.split('，')[i]])
                  }
                  this.enterData["companyValue"] = _cVal.join()
                }
              }else{
                this.$message.warning('企业不存在或存在数据异常');
              }
            }else{
              this.$message.error('请求企业数据失败');
              return;
            }
          })
          break;
        case 2:
          this.companyDevID = row.values
          this.productLine = true;
          break;
        case 3:
          this.$refs.userMessage.opendialog(row.values)
          break;
        case 5:
          this.$refs.abnormalMessage.opendialog(row.values)
          break;
        default:
          break;
      }
    }




  },
  mounted() {
    // this.getMap()
  } 
}
</script>

<style lang="less" scoped>
.map{ position: relative; width: 100%; height: 100%;
  /deep/ .loadClass{
    .el-loading-text{
      font-size: 2rem;
    }
    .el-icon-loading{
      font-size: 3rem;
    }
  }
  // img{ margin: 0 auto; display: block;}
  .typelist{ position: absolute; bottom: 5rem; left: 26%; display: flex; flex-direction: column; z-index: 111;
    li{ height: 3.29rem; width: 11.63rem; display: flex; margin-bottom: 1rem; flex-direction: row; align-items: center; cursor: pointer; font-size: 1.3rem;
      i{ display: block; width: 2.2rem; height: 2.2rem; margin-right: 1rem; margin-left: 1rem; background-image: url(../assets/images/tmap.png); background-repeat: no-repeat;}
    }
    li:hover{ color: #fff;}
    li.active{ color: #fff; background:url(../assets/images/mapck.png) no-repeat;}
    li:nth-child(1) i{ background-position: -20px -16px;}
    li:nth-child(2) i{ background-position: -20px -130px;}
    li:nth-child(3) i{ background-position: -20px -250px;}
    // li:nth-child(4) i{ background-position: -20px -364px;}
    li:nth-child(4) i{ background-position: -20px -476px;}
    li.active:nth-child(1) i{ background-position: -160px -16px;}
    li.active:nth-child(2) i{ background-position: -160px -130px;}
    li.active:nth-child(3) i{ background-position: -160px -250px;}
    // li.active:nth-child(4) i{ background-position: -160px -364px;}
    li.active:nth-child(4) i{ background-position: -160px -476px;}
  }
}
.map::before{ content: ' '; z-index: 4; position: absolute; top: 0; right: 0; width: 1560px; height: 100%; background: url(../assets/images/bgright.png) left no-repeat; background-size: contain;}
.map::after{ content: ' '; z-index: 4; position: absolute; top: 0; left: 0; width: 1560px; height: 100%; background: url(../assets/images/bgleft.png) left no-repeat; background-size: contain;}



</style>
<style lang="less">
.enterprise_dlg{ width: 100%; height: 100%; height: 60rem; overflow: hidden;
  .el-form-item{ margin-bottom: 4px; border-bottom: rgba(0, 255, 240, .2) solid 1px;
    .el-form-item__label{ text-align: center; padding:0;}
    .el-form-item__content{ text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
  }
  
}
</style>
