<template>
  <!-- 生产记录 -->
  <div class="capacity">
    <!-- <div class="search" style="padding-top:0">
      <div class="pull_left">
      </div>
      <div class="pull_right">
        <el-button icon="el-icon-plus" class="selectoprate" type="success" size="small" @click="addform">数据录入</el-button>
        <el-button icon="el-icon-edit" size="small" type="primary" @click="$refs.goodsSet.opendialog()">产品设定</el-button>
      </div>
    </div> -->
    <div class="table_column">
      <div class="tablebox">
        <el-table height="100%" :data="capacityTableList" :default-sort="{prop: 'id', order: 'descending'}" border>
          <el-table-column prop="createTime" label="录入日期" width="300" sortable>
            <template slot-scope="scope">
              <span>{{scope.row.createTime?scope.row.createTime.split(' ')[0]:scope.row.createTime}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" width="200" align="center" label="录入方式">
            <template slot-scope="scope">
              <span>{{ getentryModename(scope.row.type) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" width="300" label="录入期间">
             <template slot-scope="scope">
              <span>{{scope.row.startTime? new Date(scope.row.startTime).Format(  entryMode.find(e=>e.id==scope.row.type).format )   :scope.row.startTime}}</span>
              ~
              <span>{{scope.row.endTime?new Date(scope.row.endTime).Format(  entryMode.find(e=>e.id==scope.row.type).format ):scope.row.endTime}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="proName" label="产品名称"></el-table-column>
          <el-table-column prop="harvest" label="产出数量" width="300"></el-table-column>
          <el-table-column prop="price" label="单价/单位" width="300" sortable>
            <template slot-scope="scope">
              <span>{{scope.row.price+'/'+scope.row.unit}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="total" label="产出价值(万元)" width="300" sortable  header-align="left" align="right"></el-table-column>
          <!-- <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="edit(scope.row)">编辑</el-button>
              <el-button size="small" type="text" @click="DischargeDel(scope.row)">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="pagebox">
        <el-pagination 
          @current-change="elCurrentChange"
          @size-change="elSizeChange" 
          :current-page="currentPage" background
          :page-size="pageSize" 
          layout="total, sizes, prev, pager, next" 
          :total="totalCount">
        </el-pagination>
      </div>
    </div>

    <!-- 产能填报 -->
    <el-dialog v-dialogDrag title="产能填报数据录入" :visible.sync="dialogCapacity" width="90%" :before-close="handleClose" :close-on-click-modal="false">

      <table class="mytable">
        <thead>
          <tr>
            <!-- <th>区域/设备</th> -->
            <th>录入方式</th>
            <th>开始日期</th>
            <th>结束日期</th>
            <th style="width: 180px;">班次</th>
            <th>产品名称</th>
            <th style="width: 180px;">产出数量</th>
            <th>产出价值(万元)</th>
            <th v-if="flag=='add'" style="width: 50px;">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,i) in capacityformData" :key="i">
            <td>
              <el-select @change="item.webdatetype = changeType(item.type)" v-model="item.type" filterable placeholder="请选择录入方式">
                <el-option v-for="item in entryMode" :key="item.id" :label="item.text" :value="item.id"></el-option>
              </el-select>
            </td>
            <td>
              <el-date-picker v-model="item.startTime" value-format="yyyy-MM-dd 00:00:00" :type="item.webdatetype" placeholder="开始日期"></el-date-picker>
            </td>
            <td>
              <el-date-picker @change="capacityChangeendTime($event,i)" v-model="item.endTime" value-format="yyyy-MM-dd 23:59:59" :type="item.webdatetype" placeholder="结束日期"></el-date-picker>
            </td>
            <td>
              <el-select disabled v-model="item.clas" filterable placeholder="请选择班次">
                <el-option key="" label="全部" value="0"></el-option>
              </el-select>
            </td>
            <td>
              <el-select @change="changeproName(item,i)" v-model="item.proId" filterable placeholder="请选择产品">
                <el-option v-for="item in productData" :key="item.id" :label="item.proName" :value="item.id"></el-option>
              </el-select>
            </td>
            <td>
              <el-input v-model="item.harvest" @blur="calculateprice(item)" autocomplete="off" placeholder="请输入产出数量">
                <template slot="suffix">{{item.unit}}</template>
              </el-input>
              <!-- <span v-if="item.unit" class="dw"></span> -->
            </td>
            <td>
              <el-input v-model="item.total" autocomplete="off" placeholder="请输入产出价值"></el-input>
            </td>

            <td v-show="flag=='add'" v-if="i==0" @click="capacityformjia()"><i class="el-icon-circle-plus f_green"></i></td>
            <td v-show="flag=='add'" v-else><i class="el-icon-remove f_red" @click="capacityformjian(i)"></i></td>
          </tr>
        </tbody>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogCapacity=false;getlist()">取 消</el-button>
        <el-button size="small" type="primary" @click="flag=='edit'?capacityUpdate():capacityFill()">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 产品设定 -->
    <productSetDialog :companyId="companyId" ref="goodsSet" />



  </div>
</template>

<script>
import productSetDialog from '@/components/productSetDialog'
import api from '@/apis/DataEntry'
import devce from '@/apis/device'


export default {
  props:['companyId'],
  components: {
    productSetDialog,
  },
  data() {
    return {
      flag: 'add',
      productData: [
        //产品设定产品数据
        // {
        //     edit:true,
        //     companyId: '',//公司id
        //     metering: '', //计量方式
        //     proName: '',//产品名称
        //     proNum: '',//产品编号
        //     proPrice: '',//产品单价
        //     unit: '',//单位
        // },
      ],

      currentPage: 1,
      name: '',
      pageSize: 20,
      totalCount: 0,
      dialogEnergy: false,
      dialogCapacity: false,
      chanpinconfirm: false,
      dialogDischarge: false,
      optionhide: false,
      companys: [],
      classesData: [],
      entryMode: [
        { id: 0, text: '按年',key:'year'  ,format:'yyyy' },
        { id: 1, text: '按月',key:'month' ,format:'yyyy-MM' },
        { id: 2, text: '按日',key:'date'  ,format:'yyyy-MM-dd' },
      ],

      capacityformData: [
        // {
        //     companyId: '',
        //     areaId: '',
        //     capitalId: '',
        //     capitalName: '',
        //     clas: '0',
        //     startTime: '',
        //     endTime: '',
        //     type: '',//录入方式
        //     price: '',//商品总价
        //     proId: '',//商品id
        //     proName: '',//商品名称
        //     harvest: '',//产量
        //     unit: '',//单位
        //     total: '',//总价
        //     webdatetype:'date'
        // }
      ],
      energyTableList: [],
      capacityTableList: [],
      DischargeList: [],
      energyformData: [],
      // permissions:[],//权限
    }
  },
  methods: {
    getentryModename(id) {
      for (const iterator of this.entryMode) {
        if (id == iterator.id) {
          return iterator.text
        }
      }
    },

    getlist() {
      api.capacityList({
          params: {
            companyId:this.companyId,
            rId:sessionStorage.getItem('capID'),
            limit: this.pageSize,
            page: this.currentPage,
            rType: 1,       
          },
        }).then((res) => {
          if (res.code == 10000) {
            this.totalCount = res.data.totalCount
            this.capacityTableList = res.data.list
            // this.$message.success(res.message)
          } else {
            this.$message.warning(res.message)
          }
        })
    },
    edit(row) {
      this.flag = 'edit'
      this.capacityformData = [row]
      this.dialogCapacity = !this.dialogCapacity
    },
    DischargeDel(row) {
      this.$confirm('确认删除？ 不可恢复！', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          api
            .capacityDel({
              params: {
                id: row.id,
              },
            })
            .then((res) => {
              if (res.code == 10000) {
                this.$message.success(res.message)
                this.getlist()
              } else {
                this.$message.warning(res.message)
              }
            })
        })
        .catch(() => {})
    },
    //分页
    elCurrentChange(val) {
      this.currentPage = val
      this.getlist()
    },
    elSizeChange(val) {
      this.pageSize = val
      this.getlist()
    },
    addform() {
      let _capIDAdd = sessionStorage.getItem('capID')
      this.flag = 'add'
      this.capacityformData = [
        {
          rId: _capIDAdd,
          companyId: this.companyId,
          rName: '',
          clas: '0',
          startTime: '',
          endTime: '',
          rType: 1, //录入方式
          price: '', //商品总价
          proId: '', //商品id
          proName: '', //商品名称
          harvest: '', //产量
          unit: '', //单位
          total: '', //总价
          webdatetype: 'date',
        },
      ]
      this.$nextTick(()=>{
        devce.getDeviceDetails(_capIDAdd).then(res2=>{
          if(res2.code==10000){
            this.capacityformData[0].rName = res2.data.name
          }
        })
      })
      this.dialogCapacity = !this.dialogCapacity
    },
    //录入
    capacityformjia() {
      if (this.flag == 'edit') {
        return this.$message.warning('编辑状态下不能添加！')
      }
      devce.getDeviceDetails(sessionStorage.getItem('capID')).then(res2=>{
        if(res2.code==10000){
          //添加一行产品
          this.capacityformData.push({
            rId: sessionStorage.getItem('capID'),
            companyId: this.companyId,
            rName: res2.data.name,
            clas: '0',
            startTime: '',
            endTime: '',
            rType: 1, //录入方式
            price: '', //商品总价
            proId: '', //商品id
            proName: '', //商品名称
            harvest: '', //产量
            unit: '', //单位
            total: '', //总价
            webdatetype: 'date',
          })
        }else{
          this.$message.error('获取设备信息失败，无法添加');
          return;
        }
      })


    },

    capacityformjian(index) {
      //产能填报
      this.capacityformData.splice(index, 1)
    },

    capacityFill() {
      //检查有无为空属性
      for (const item of this.capacityformData) {
        // console.log(item)
        for (const iterator in item) {
          if (item[iterator] === '') {
            // console.log(iterator)
            this.$message({
              message: '请填写所有项目！',
              type: 'error',
              duration: '1000',
            })
            return
          }
        }
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      api.capacityFill(this.capacityformData).then((res) => {
        loading.close() //关闭
        if (res.code == 10000) {
          this.getlist()
          this.dialogCapacity = !this.dialogCapacity
          this.capacityformData = []
          this.$message.success(res.message)
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    capacityUpdate() {
      let _capID = sessionStorage.getItem('capID')
      //修改
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.capacityformData[0].areaId = _capID
      this.capacityformData[0].capitalId = _capID
      devce.getDeviceDetails(_capID).then(res1=>{
        if(res1.code==10000){
          this.capacityformData[0].rName = res1.data.name
        }
      })
      api.capacityUpdate(this.capacityformData[0]).then((res) => {
        loading.close() //关闭
        if (res.code == 10000) {
          this.dialogCapacity = !this.dialogCapacity
          this.capacityformData = []
          this.getlist()
          this.$message.success(res.message)
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    changeType(id) {
      switch (id) {
        case 0:
          return 'year'
        case 1:
          return 'month'
        case 2:
          return 'date'
        default:
          return 'date'
      }
    },

    capacityChangeendTime(newVal, i) {
      //产能 结束日期
      if (newVal === null) {
        return
      }
      //判断录入方式是否为空 （年月日）
      if (this.capacityformData[i].type === '') {
        this.$message({
          message: '请先选择录入方式！',
          type: 'error',
          duration: '1000',
        })
        this.capacityformData[i].endTime = '' //置空
        return
      }
      //开始日期和结束日期判断
      if (this.capacityformData[i].startTime) {
        var Dstart = new Date(this.capacityformData[i].startTime)
        let startTimestamp = parseInt(Dstart.getTime() / 1000) //开始日期时间戳
        var Dend = new Date(newVal)
        let endTimestamp = parseInt(Dend.getTime() / 1000) //结束日期时间戳

        if (startTimestamp > endTimestamp) {
          this.$message({
            message: '结束日期不能大于开始日期！',
            type: 'error',
            duration: '1500',
          })
          this.capacityformData[i].endTime = '' //置空
        }
      } else {
        this.$message({
          message: '请先选择开始日期！',
          type: 'error',
          duration: '1000',
        })
        this.capacityformData[i].endTime = '' //置空
        return
      }
      //按照录入方式 判断当前月或的最后一天
      if (this.capacityformData[i].type == 1) {
        // console.log(newVal)
        var d1 = new Date(newVal)
        var Month = d1.getMonth() //月份,返回的结果0~11
        // console.log('月份',Month)
        var d2str
        if (Month >= 11) {
          //选择12月应加上一年
          d2str = `${d1.getFullYear() + 1}-1-${d1.getDate()} 23:59:59`
        } else {
          d2str = `${d1.getFullYear()}-${Month + 2}-${d1.getDate()} 23:59:59`
        }
        d1 = new Date(d2str) //加了一月的时间
        //获取时间戳减去一天
        var timestamp = parseInt(d1.getTime() / 1000) - 86400
        // console.log(timestamp)
        d1 = new Date(timestamp * 1000) //减去一天的时间
        var d2 = `${d1.getFullYear()}-${
          d1.getMonth() + 1
        }-${d1.getDate()} 23:59:59`
        // console.log(newVal, d2str, d2)
        this.capacityformData[i].endTime = d2
      }
    },
    changeproName(item) {
      //获取产品名字  添加产品编号

      // if(name=='非标产品'){
      //     this.capacityformData[i].proId = 0
      //     this.capacityformData[i].unit = "无"
      //     this.capacityformData[i].price = 1
      //     this.capacityformData[i].harvest = 1
      //     return false;
      // }

      for (const row of this.productData) {
        if (item.proId == row.id) {
          item.proName = row.proName
          item.price = row.proPrice
          item.unit = row.unit
          break
        }
      }

      if (item.harvest) {
        item.total = parseInt(item.harvest) * item.price
      }
    },
    calculateprice(item) {
      //计算产量
      if (item.harvest) {
        item.total = parseInt(item.harvest) * item.price
      }
    },

    productList() {
      let params = {
        companyId:this.companyId
      }
      //获取产品列表
      api.productList({params}).then((res) => {
        if (res.code == 10000) {
          // console.log(res)
          this.productData = res.data
          for (const iterator of this.productData) {
            this.$set(iterator, 'edit', true)
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },




    handleClose(done) {
      if (this.flag != 'look') {
        this.$confirm('确认关闭？ 数据不会被保存！')
          .then(() => {
            done()
          })
          .catch(() => {})
      } else {
        done()
      }
    },

  },
  mounted() {
    if(sessionStorage.getItem('capID')){
      // this.permissions = JSON.parse(localStorage.getItem('permissions'))
      this.productList()
      // if(this.permissions.includes('custom:device:list')){
        this.getlist()
      // }else{
      //   this.$message.error('没有权限');
      //   return;
      // }
    }
  },
}
</script>

<style lang="less" scope>
.capacity {
  width: 100%; height: 100%; flex:1; overflow: hidden; display: flex; flex-direction: column;
  .search{ border: none;}
  .table_column{ height: 100%; flex: 1; overflow: hidden; display: flex; flex-direction: column;
    .tablebox{ height: 100%; flex: 1; overflow-y: auto;}
    .pagebox{ text-align: center; padding-top: 10px;}
  }
}
</style>
