<template>
  <div class="header">
    工业产业互联网大数据共享平台
    <!-- <div class="date">
      <el-select @change="dataTypeChange" v-model="dateType" placeholder="请选择" style="width:90px">
        <el-option v-for="(item,index) in dateTypes" :key="index" :label="item.name" :value="item.value"></el-option>
      </el-select>

      <el-date-picker  
        v-if="dateType=='1d,-8h'" 
        v-model="dateDay"
        key="day" 
        type="daterange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-date-picker  
        v-else-if="dateType=='1M'" 
        v-model="dateDay" 
        key="month" 
        type="monthrange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <div style="display:inline" v-else>
        <el-date-picker
          v-model="yearFirst"
          type="year"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择起始年">
        </el-date-picker>
        <span>至</span>
        <el-date-picker
          v-model="yearLast"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="year"
          placeholder="选择结束年">
        </el-date-picker>
      </div>
      <el-button @click="searchData" style="padding:.2rem 12px" size="mini" type="primary">查询</el-button>
    </div> -->
    <div class="time">{{currentDate}}
      <!-- <img src="../assets/images/img/weather.png"> -->
      <iframe width="450" scrolling="no" height="40" frameborder="0" allowtransparency="true" src="https://i.tianqi.com?c=code&id=34&color=%23FFFFFF&icon=1&site=24"></iframe>
    </div>
    <div  @click="outlogin" class="exit"><i class="el-icon-switch-button"></i></div>
  </div>
</template>

<script>
import api from '@/apis/login'
export default {
  data() {
    return {
      currentDate:'',
      // dateDay:[new Date(new Date().getTime()-3600 * 1000 * 24 * 30).Format("yyyy-MM-dd hh:mm:ss"),new Date().Format("yyyy-MM-dd hh:mm:ss")],//关联日期
      // yearFirst: '',//关联起始年
      // yearLast: '',//关联结束年
      // dateType:'1M',//年月日
      // dateTypes:[
      //   {id:0,name:'日',value:'1d,-8h'},
      //   {id:1,name:'月',value:'1M'},
      //   {id:2,name:'年',value:'1Y'},
      // ]
    };
  },
  methods:{
    outlogin() {
      this.$confirm('确认退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.LoginOut().then(() => {
          localStorage.clear()
          // localStorage.removeItem("userId");
          // localStorage.removeItem("token");
          // localStorage.removeItem("permissions");
          // localStorage.removeItem("tabCache");
          // localStorage.removeItem("username");
          // this.$router.replace("/");
          location.replace('/')
        })
      })
    },



    //日期类型切换
    // dataTypeChange(val){
    //   if(val=='1Y'){
    //     this.yearFirst = ''
    //     this.yearLast = ''
    //   }else{
    //     this.dateDay = ''
    //   }
    // },
    // //确定按钮
    // searchData(){
    //   if(this.dateType=='1d,-8h'&&this.dateDay == ''){
    //       this.$message.warning('请选择时间');
    //       return;  
    //   }
    //   if(this.dateType=='1M'&&this.dateDay == ''){
    //       this.$message.warning('请选择时间');
    //       return;  
    //   }
    //   if(this.dateType=='1Y'){
    //     if(!this.yearFirst||!this.yearLast){
    //       this.$message.warning('请选择起止年份');
    //       return;
    //     }
    //     if(new Date(this.yearFirst).getTime()<new Date(this.yearLast).getTime()){
    //       this.dateDay = [this.yearFirst,this.yearLast]
    //     }else{
    //       this.$message.warning('开始时间不能大于结束时间');
    //       return;
    //     }
    //   }
    //   this.$emit('Cdate',{
    //     date:this.dateDay,
    //     dateType:this.dateType,
    //   })
    // },
  },
  mounted(){
    // this.searchData();//初始化

    setInterval(()=>{
      this.currentDate = new Date().toLocaleString();
    },1000); 
  }
}
</script>

<style lang="less" scoped>
.header{ height: 140px; position: absolute; z-index: 5; top: 0; left: 0; background: url(../assets/images/headerbg.png) center bottom no-repeat; background-size: contain;
  line-height: 100px; color: #fff; font-weight: 500; font-size: 2.2rem; text-align: center;
  .time{ position: absolute; top:1.6rem; right:1360px; padding-right: 4rem; font-size: 1.1rem; color: #ccd0f2;
    img{ margin-left: 2rem; vertical-align: middle;}
    iframe{ margin-left: 1rem; position: relative; top:13px;}
  }
  .date{position: absolute; top:1.6rem; left:1360px; padding-left: 4rem; font-size: 1.1rem; color: #ccd0f2; line-height: 1; padding-top:36px;
    .el-range-editor.el-input__inner{ position: relative; top:0px}
  }
  .exit{ font-size: 2rem; cursor: pointer; line-height: 1; color: #fff; position: absolute; top:.4rem; right: .5rem;}
}
</style>
 