<template>
  <div class="dev-basci-msg">
    <div v-if="detailsData.length==0" class="no-data-title">请选择设备</div>
    <div 
      v-else 
      class="main_box"
      v-loading="isLoadingBasic" 
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="main_left">
        <div class="dev-msg-title">
          <span>{{titleName}}</span>
          <!-- <el-tag :type="titleStatus=='正常'?'':'danger'">{{titleStatus}}</el-tag> -->
        </div>
        <div v-if="isEditStatus" class="thumb-example">
          <!-- swiper1 -->
          <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide v-for="(img,index) in swiperData" :key="index">
              <img :src="img">
            </swiper-slide>
            <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
            <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
          </swiper>
          <!-- swiper2 Thumbs -->
          <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
            <swiper-slide v-for="(img,index) in swiperData" :key="index">
              <img :src="img">
            </swiper-slide>
          </swiper>
        </div>
        <div v-else style="border-top:#ddd solid 1px; padding-top:10px;">
          <!-- <div class="add-title">设备图片</div> -->
          <el-upload
            action="/"
            :http-request="updateImg"
            list-type="picture-card"
            :before-remove="beforeRemovePic"
            :before-upload="beforeAvatarUpload"
            :file-list="showPicList"
            :limit="10"
            >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <div class="main_right">
        <!-- 基本信息-查看 -->
        <div v-if="isEditStatus">
          <div class="basci-right-table" v-for="(item,index) in detailsData" :key="index+'s1'">
            <span>{{item.label|dataTrans('devInformation')}}：</span>
            <span>{{item.value}}</span>
          </div>
          <div class="basci-right-table" v-for="(item,index) in detailsExtraData" :key="index+'s2'">
            <span>{{item.label}}：</span>
            <span>{{item.value}}</span>
          </div>
          <!-- <div class="btn_group">
            <el-button size="small" @click="editDevice" type="primary">编 辑</el-button>
            <el-button size="small" @click="delDevice" type="danger">删 除</el-button>
          </div> -->
        </div>
        <!-- 基本信息-编辑form -->
        <el-form v-else :model="ruleForm" ref="ruleForms" :rules="baseInfoRule" label-width="120px" class="demo-ruleForm">
          <el-row style="padding-top:20px">
            <el-col :span="24">
              <el-form-item label="设备名称" prop="name">
                <el-input show-word-limit maxlength="20" type="text" v-model="ruleForm.name" placeholder="请输入设备名称"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="设备类型" prop="type">
                <el-select v-model="ruleForm.type" placeholder="请选择设备类型">
                  <el-option label="单台设备" :value="0"></el-option>
                  <el-option label="生产线" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="设备品牌" prop="brand">
                <el-input show-word-limit maxlength="20" type="text" v-model="ruleForm.brand" placeholder="请输入设备品牌"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备型号" prop="marking">
                <el-input show-word-limit maxlength="20" type="text" v-model="ruleForm.marking" placeholder="请输入设备型号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="安装区域" prop="area">
                <el-select ref="configSelectEdit" clearable v-model="ruleForm.area" placeholder="请选择安装区域">
                  <el-option style="height:auto;padding:0 15px;background:#fff;" value="areaname">
                    <el-tree
                      node-key="areaId"
                      ref="area"
                      default-expand-all
                      :data="areaTreeData"
                      :props="defaultPropsArea"
                      :check-on-click-node="true"
                      :expand-on-click-node='false'
                      :check-strictly="true"
                      @check-change="handleCheckChangeArea"
                    >
                    </el-tree>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="安装位置" prop="address">
                <el-row>
                  <el-col :span="20">
                    <el-input ref="anZhuangWeiZhi" v-model="ruleForm.address" placeholder="请输入安装位置"></el-input>
                  </el-col>
                  <el-col :span="4" style="text-align:right;">
                    <el-button type="primary" size="small" @click="openBiaozhu">地图标注</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <!-- 经纬度 -->
            <el-col :span="12">
              <el-form-item label="经度" prop="longitude">
                <el-input v-model="ruleForm.longitude" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度" prop="latitude">
                <el-input v-model="ruleForm.latitude" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="负责人" prop="linkUser">
                <el-select clearable filterable v-model="ruleForm.linkUser" placeholder="请选择负责人">
                  <el-option 
                    v-for="(user,i) in principalList" 
                    :key="i" 
                    :label="user.name" 
                    :value="user.userId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="制造日期" prop="outTime">
                <el-date-picker
                  v-model="ruleForm.outTime"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="安装日期" prop="installTime">
                <el-date-picker
                  v-model="ruleForm.installTime"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="售后截止日期" prop="serviceTime">
                <el-date-picker
                  v-model="ruleForm.serviceTime"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="制造厂商" prop="firm">
                <el-input v-model="ruleForm.firm" placeholder="请输入制造厂商"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="使用状态" prop="useStatus">
                <el-select v-model="ruleForm.useStatus" placeholder="请选择使用状态">
                  <el-option label="正常自用" :value="1"></el-option>
                  <el-option label="闲置" :value="2"></el-option>
                  <el-option label="待出租" :value="3"></el-option>
                  <el-option label="出租中" :value="4"></el-option>
                  <el-option label="出售中" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运行状态" prop="runStatus">
                <el-select v-model="ruleForm.runStatus" placeholder="请选择运行状态">
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="关机" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保养周期" prop="maintain">
                <el-input type="number" v-model="ruleForm.maintain" placeholder="请输入保养周期">
                  <template slot="suffix">日/次</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保单位" prop="construct">
                <el-select v-model="ruleForm.construct" placeholder="请选择使用状态">
                  <el-option label="厂家售后" value="1"></el-option>
                  <el-option label="自行维保" value="2"></el-option>
                  <el-option label="维保外包" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-for="(mod,index) in moduleData" 
                :key="index" >
              <!-- 每个设备特定模型 -->
              <el-form-item 
                :label="mod.propertyName" 
                :prop="mod.propertyEmpty=='no'?mod.propertyRow:''">
                <el-select 
                  value-key="id" 
                  @change="((value)=>{propertyChange(value,mod,index)})" 
                  v-if="mod.propertyInput=='select'" 
                  v-model="ruleForm.propertyFiled[index][mod.propertyRow]" 
                  placeholder="请选择">
                  <el-option 
                    v-for="(item,dex) in mod.resourceValue?JSON.parse(mod.resourceValue):[]" 
                    :key="dex" 
                    :label="item" 
                    :value="item">
                  </el-option>
                </el-select>
                <el-input 
                  v-else 
                  :type="mod.propertyType=='number'?'number':'text'" 
                  v-model="ruleForm.propertyFiled[index][mod.propertyRow]" 
                  :placeholder="'请输入'+mod.propertyName">
                  <template slot="suffix">{{mod.unit}}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="btn_group">
            <el-button size="small" @click="cancelEdit">取 消</el-button>
            <el-button size="small" :loading="updataLoading" @click="updataDevice('ruleForms')" type="primary">保 存</el-button>
          </div>
        </el-form>
      </div>
    </div>
  

    <!-- 百度地图弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      top="5vh"
      v-dialogDrag
      :modal="false"
      class="bmap-dialog"
      id="dituDialogDetails"
      title="标注坐标"
      :visible.sync="dialogVisible"
      width="60%">
      <div class="map-content">
      <baidu-map class="map_baidu" :center="map.center" :zoom="map.zoom" @ready="handler">
        <bm-boundary :name="contour" :fillOpacity="0.1" :strokeWeight="2" strokeColor="blue"></bm-boundary>
        <bm-local-search :location="location" :keyword="keyword" :auto-viewport="true"></bm-local-search>
        <!--点-->
        <bm-marker @click="infoWindowOpen" :position="map.position" :dragging="map.dragging" animation="BMAP_ANIMATION_DROP">
        </bm-marker>
      </baidu-map>
    </div>
      <span slot="footer" class="dialog-footer">
        <label>关键词搜索：<input style="margin-right:1.42em;height:1.72rem;padding-left:4px;" v-model="keyword"></label>
        <el-button size="mini" type="primary" @click="getLatLng">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import api from '@/apis/device'
// import area from '@/apis/power/monitor'
import load from '@/apis/upload'

export default {
  props:['devID','companyId'],
  components: {
    swiper,
    swiperSlide
  },
  watch:{
    devID(val){
      if(val){
        this.getDevDetail(val)
      }else{
        this.detailsData = []
      }
    },
  },
  data(){
    return{
      //地图
      dialogVisible:false,//地图弹框
      contour:'',//区域轮廓默认德阳
      location:'',//默认地区
      keyword:'',//关键词搜索
      map:{//百度地图数据
        center:'',
        zoom: 12,
        show: false,
        dragging: true,
        position:{lng:'',lat:''}//定点
      },
      //组件内
      detailsData:[],//查看详情的数据
      detailsExtraData:'',//查看详情的模型额外数据
      isEditStatus:true,//是否编辑状态
      isLoadingBasic:false,//加载状态
      titleName:'',//首页题目名字
      titleStatus:'',//首页题目状态
      // 上传
      showPicList:[],//回显图片信息
      temPicList:[],//保存图片信息
      updataLoading:false,//更新按钮加载状态
      //编辑设备
      areaTreeData:[],//区域树数据
      moduleData:'',//特定模型数据
      principalList:'',//负责人数据
      //区域树字段
      defaultPropsArea: {
        label: "areaName",
        children: "companyAreasDtos",
        areaId:'areaId'
      },
      //表单验证规则
      ruleForm: {//form数据
        name: '',//设备名称
        // type: '',//设备类型
        brand: '',//设备品牌
        marking: '',//设备型号
        area: '',//安装区域
        address: '',//安装位置
        longitude: '',//经度
        latitude: '',//纬度
        linkUser: '',//负责人
        outTime: '',//制造日期
        installTime: '',//安装日期
        serviceTime: '',//售后截止日期
        firm: '',//制造厂商
        useStatus: '',//使用状态
        runStatus: '',//运行状态
        maintain:'',//保养周期
        construct:'',//维保单位
        pictures:[],//设备图片
        propertyFiled:[],//模型json数据
      },
      baseInfoRule:{//验证规则
        name: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        // type: [
        //   { required: true, message: '请选择设备类型', trigger: 'change' }
        // ],
        brand: [
          { required: true, message: '请输入设备品牌', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        marking: [
          { required: true, message: '请输入设备型号', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '请选择安装区域', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入安装位置', trigger: 'blur' },
        ],
        longitude: [
          { required: true, message: '请在地图上标注', trigger: 'blur' },
        ],
        latitude: [
          { required: true, message: '请在地图上标注', trigger: 'blur' },
        ],
        linkUser: [
          { required: true, message: '请选择负责人', trigger: 'change' }
        ],
        outTime: [
          { required: true, message: '请选择制造日期', trigger: 'change' }
        ],
        installTime: [
          { required: true, message: '请选择安装日期', trigger: 'change' }
        ],
        serviceTime: [
          { required: true, message: '请选择售后截止日期', trigger: 'change' }
        ],
        firm: [
          { required: true, message: '请输入制造厂商', trigger: 'blur' },
        ],
        useStatus: [
          { required: true, message: '请选择使用状态', trigger: 'change' }
        ],
        runStatus: [
          { required: true, message: '请选择运行状态', trigger: 'change' }
        ],
        maintain: [
          { required: true, message: '请输入保养周期', trigger: 'blur' },
        ],
        construct: [
          { required: true, message: '请选择维保单位', trigger: 'change' }
        ],
      },


      //匹配数据
      matchData:{
        useStatus:['正常自用','闲置','待出租','出租中','出售中'],
        runStatus:['正常','关机'],
        construct:['厂家售后','自行维保','维保外包'],
      },

      //swiper数据
      swiperData:'',//swipe图片数据
      swiperOptionTop: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        // watchSlidesVisibility: true,/*避免出现bug*/
      },
      permissions:[],//权限
    }
  },
  methods:{
    //地图api
    //展开详情
    infoWindowOpen(){
      this.map.show = true;
    },
    //点击确定获取经纬度
    getLatLng(){
      if(!this.ruleForm.address){
        this.$confirm('未选取坐标, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dialogVisible = false
          this.$refs.anZhuangWeiZhi.focus()
        })
      }else{
        this.dialogVisible = false
        this.$refs.anZhuangWeiZhi.focus()
      }
    },
    //打开地图标注
    openBiaozhu(){
      this.dialogVisible = true;
      this.map.center = '德阳'
      this.contour = ''
      this.location = '德阳'
    },
    //百度加载完毕方法
    handler ({BMap,map}) {
      let _this = this;
      //定位
      // var geolocation = new BMap.Geolocation();
      // geolocation.getCurrentPosition(function(r){
      //   // var mk = new BMap.Marker(r.point);
      //   // map.addOverlay(mk);
      //   map.panTo(r.point);
      // },{enableHighAccuracy: true})
      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener('click', function (e) {
        // console.log(e.point.lng, e.point.lat)
        _this.ruleForm.longitude = e.point.lng
        _this.ruleForm.latitude = e.point.lat
        _this.map.position.lng = e.point.lng
        _this.map.position.lat = e.point.lat
        var point = new BMap.Point(e.point.lng, e.point.lat);
        var gc = new BMap.Geocoder();
        gc.getLocation(point, function (rs) {
          // console.log(rs.addressComponents);//地址信息
          _this.ruleForm.address = 
            rs.addressComponents.province + '-' +
            rs.addressComponents.city + '-' +
            rs.addressComponents.district
          if(rs.addressComponents.street){
            _this.ruleForm.address = _this.ruleForm.address + '-' + rs.addressComponents.street
          }
          if(rs.addressComponents.streetNumber){
            _this.ruleForm.address = _this.ruleForm.address + '-' + rs.addressComponents.streetNumber
          }
        })
      })
    },
    //获取区域树结构
    recursiveFunction() {
      api.findCapitalAreas(this.companyId).then((res) => {
        if (res.code == 10000) {
          this.areaTreeData = res.data;
        }
      });
    },
    //获取负责人
    getUserList(){
      api.getAllUser(this.companyId).then(res=>{
        if(res.code==10000){
          if(res.data.length!=0){
            this.principalList = res.data
          }else{
            this.principalList = []
          }
        }else{
          this.$message.error(res.message);
          return;
        }
      })
    },
    //区域选择事件
    handleCheckChangeArea(val) {
      this.ruleForm.area = val.areaName
      this.ruleForm.areaId = val.areaId
      this.$refs.configSelectEdit.blur()
    },
    //特定设备模型选择框切换事件
    propertyChange(msg,name,index){
      this.$forceUpdate()
      this.ruleForm.propertyFiled[index] = {
        name:name.propertyName
      }
      this.ruleForm.propertyFiled[index][name.propertyRow] = msg
    },
    //获取设备详情-查看
    getDevDetail(id){
      this.isLoadingBasic = true;
      this.isEditStatus = true;//查看编辑切换
      this.getUserList()//获取负责人
      setTimeout(()=>{//睡一秒
        api.getDeviceDetails(id).then(res=>{
          if(res.code==10000){
            let _arr = []
            let _arrExtra = []
            let _data =res.data
            let propert;
            let photos;
            if(_data.propertyFiled){
              propert = JSON.parse(_data.propertyFiled) 
            }else{
              propert = ''
            }
            if(_data.pictures){
              photos = JSON.parse(_data.pictures) 
            }else{
              photos = ''
            }
            if(photos.length!=0){
              let _photosArr = []
              for(var p in photos){
                _photosArr.push(photos[p].url)
              }
              this.swiperData = _photosArr
            }else{
              this.swiperData = [require('@/assets/images/isNoPic.png')]
            }
            if(propert){
              for(var j in propert){
                let pro = {
                  value:propert[j][Object.keys(propert[j])[1]],
                  label:propert[j].name
                }
                _arrExtra.push(pro)
              }
              this.detailsExtraData = _arrExtra
            }else{
              this.detailsExtraData = []
            }
            for(var i in _data){
              if(i=='areaId'||i=='companyId'||i=='createTime'||i=='id'||i=='modeId'
              ||i=='pictures'||i=='updateTime'||i=='propertyFiled'||i=='deviceEnergies'){
                continue
              }
              // if(!_data[i]){
              //   _data[i] = '暂无信息'
              // }
              if(i=='installTime'||i=='outTime'||i=='serviceTime'){
                if(_data[i]){
                  _data[i] = _data[i].substring(0,10)
                }else{
                  _data[i] = ''
                }
              }
              if(i=='useStatus'){
                _data[i] = this.matchData.useStatus[_data[i]-1]
              }
              if(i=='runStatus'){
                _data[i] = this.matchData.runStatus[_data[i]-1]
              }
              if(i=='construct'){
                _data[i] = this.matchData.construct[parseInt(_data[i])-1]
              }
              if(i=='linkUser'){
                let _userMsg = this.principalList
                for(var x in _userMsg){
                  if(_data[i]==_userMsg[x].userId){
                    _data[i] = _userMsg[x].name
                  }
                }
              }
              let obj = {
                value:_data[i],
                label:i
              }
              _arr.push(obj)
            }
            this.detailsData = _arr 
            //标题
            this.titleName = _data.name
            this.titleStatus = _data.runStatus
            //swiper画图
            this.$nextTick(()=>{
              const swiperTop = this.$refs.swiperTop.swiper
              const swiperThumbs = this.$refs.swiperThumbs.swiper
              swiperTop.controller.control = swiperThumbs
              swiperThumbs.controller.control = swiperTop
            })
            this.isLoadingBasic = false;
          }else{
            this.$message.error(res.message);
            this.isLoadingBasic = false;
            return;
          }
        })
      },200)
    },
    //编辑设备信息
    editDevice(){
      this.recursiveFunction()//获取区域树
      this.isEditStatus = false;
      this.showPicList = []
      let _id = sessionStorage.getItem('capID');
      api.getDeviceDetails(_id).then(res=>{
        if(res.code==10000){
          api.getModuleList(res.data.modeId).then(data=>{
            if(data.code==10000){
              if(data.data.length!=0){
                this.moduleData = data.data
              }else{
                this.moduleData = []
              }
            }
          }).then(()=>{
            let _rdata = res.data
            if(!_rdata.propertyFiled){
              _rdata.propertyFiled = []
              this.moduleData = []
            }else{
              _rdata.propertyFiled = JSON.parse(_rdata.propertyFiled)
            }

            if(!_rdata.pictures){
              _rdata.pictures = []
            }else{
              this.showPicList = JSON.parse(_rdata.pictures)
              _rdata.pictures = JSON.parse(_rdata.pictures)
            }
            this.ruleForm = _rdata
          })
        }
      })
    },
    //删除设备信息
    delDevice(){
      var _id = sessionStorage.getItem('capID')
      this.$confirm('确认删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.deleteDevice(_id).then(res=>{
          if(res.code==10000){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.$emit('isDelDev',true)
            this.detailsData = []
          }else{
            this.$message.error(res.message);
            return;
          }
        })
      })
    },
    //取消编辑设备信息
    cancelEdit(){
      this.getDevDetail(sessionStorage.getItem('capID'))
      this.isEditStatus = true;
    },
    //更新修改设备信息
    updataDevice(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let _data = this.ruleForm
          this.updataLoading = true;
          if(new Date(_data.outTime).getTime()>new Date(_data.installTime).getTime()){
            this.$message.error('制造日期不能大于安装日期');
            this.updataLoading = false;
            return;
          }else{
            if(_data.propertyFiled.length==0){
              _data.propertyFiled = ''
            }else{
              _data.propertyFiled = JSON.stringify(_data.propertyFiled)      
            }

            if(_data.pictures){
              _data.pictures = JSON.stringify(_data.pictures)
            }else{
              _data.pictures = ''
            }
          }
          api.updateDevice(_data).then(res=>{
            if(res.code==10000){
              this.$message.success('修改成功');
              this.$emit('isDelDev',false)
              this.updataLoading = false;
              this.isEditStatus = true;
              this.getDevDetail(sessionStorage.getItem('capID'))
            }else{
              this.$message.error(res.message);
              this.updataLoading = false;
              return;
            }
          })
        } else {
          return false;
        }
      });
    },







    // 上传图片
    updateImg(val) {
      let fd = new FormData();
      fd.append("file", val.file);
      load.upload(fd).then((res) => {
        if (res.code == 10000) {
          this.ruleForm.pictures.push({
            name: val.file.name,
            url: res.data,
            uid: val.file.uid,
          });
          // 显示图片
          this.showPicList.push({
            name: val.file.name,
            url: res.data,
            uid: val.file.uid,
          });
          this.$message({
            message: "上传成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
          return;
        }
      });
    },
    //判断上传文件格式是否合法
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 删除图片
    beforeRemovePic(file) {
      return this.$confirm(`确定移除 ${file.name}？`).then(() => {
        this.showPicList.map((item, index) => {
          if (item.uid == file.uid) {
            // 删除
            api.delUploadImg({ filePath: item.url }).then((res) => {
              if (res.code == 10000) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.showPicList.splice(index, 1);
                this.ruleForm.pictures.splice(index, 1);
              } else {
                this.$message({
                  message: res.message,
                  type: "warning",
                });
                return;
              }
            });
          }
        });
      });
    },
  },
  mounted() {
    //权限
    this.permissions = JSON.parse(localStorage.getItem('permissions'))

    if(sessionStorage.getItem('capID')){
      this.getDevDetail(sessionStorage.getItem('capID'))
    }
  }
}
</script>
<style lang="less" scoped>
.dev-basci-msg{ height: 100%; overflow: hidden;
.no-data-title{
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 40%;
  left: 45%;
}
  .main_box{ width: 100%; height: 100%; display: flex; flex-direction: row; overflow:hidden;
    .main_left{ width: 30rem;
      .dev-msg-title{ display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-bottom: 10px;
        span:first-child{ font-size: 1.15rem; font-weight: bold;}
      }
      .thumb-example{
        .gallery-top{ border: #005ae4 solid 1px; width: 30rem; height: 30rem;
          .swiper-wrapper{
            .swiper-slide{ display: flex; align-items: center; justify-content: center;}
          }
          img{ max-width: 30rem; max-height: 30rem;}
        }
        .gallery-thumbs{ margin-top: 10px;
          .swiper-wrapper{ display: flex; flex-direction: row; justify-content: center; align-items: center; 
            .swiper-slide{ border: #005ae4 solid 1px; display: flex; align-items: center; width: 19%; height: 5.5rem; opacity: .6;
              img{ max-width: 100%; max-height: 100%;}
            }
            .swiper-slide-active{ opacity: 1;}
          }
        }
      }
    }
    .main_right{ flex: 1; height: 100%; overflow-y: auto; margin-left: 20px; padding:0px 20px;
      .basci-right-table{ display: flex; flex-direction: row; border-bottom: #005ae4 dashed 1px;
        span:first-child{ width: 220px; text-align: right; padding-right: 5px; opacity: .6;}
        span{ line-height: 3rem;}
        span:last-child{ font-weight: bold;}
      }
      .btn_group{text-align:right; margin:18px 0; padding:10px 0; border-top: #005ae4 solid 1px;}
    }
  }
}
</style>