<template>
    <div class="energymsg">
        <el-form ref="formData" :model="formData" label-width="240px" class="myform">
          <el-form-item label="设计日运行时长" prop="runTime">
            <el-select disabled style="width:80%" v-model="formData.runTime" placeholder=" ">
              <el-option v-for="(item,index) in 24" :key="index" :label="item" :value="item"></el-option>
            </el-select>
            <span> 小时</span>
          </el-form-item>
          <el-form-item label="是否重点用能设备" prop="point">
            <el-select disabled v-model="formData.point" placeholder=" ">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消耗能源类别" prop="energyType">
            <el-select disabled v-model="formData.energyType" placeholder=" ">
              <el-option label="电力" :value="1"></el-option>
              <el-option label="水力" :value="2"></el-option>
              <el-option label="燃气" :value="3"></el-option>
              <el-option label="燃油" :value="4"></el-option>
              <el-option label="燃煤" :value="5"></el-option>
              <el-option label="蒸汽" :value="6"></el-option>
              <el-option label="其他" :value="7"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="额定功率" prop="powerRated">
            <el-input disabled type="number" v-model="formData.powerRated">
              <template slot="suffix">千瓦</template>
            </el-input>
          </el-form-item>
          <el-form-item label="满载功率" prop="powerLoad">
            <el-input disabled type="number" v-model="formData.powerLoad">
              <template slot="suffix">千瓦</template>
            </el-input>
          </el-form-item>
          <el-form-item label="空载功率" prop="powerIdler">
            <el-input disabled type="number" v-model="formData.powerIdler">
              <template slot="suffix">千瓦</template>
            </el-input>
          </el-form-item>
          <el-form-item label="待机功率" prop="powerStandby">
            <el-input disabled type="number" v-model="formData.powerStandby">
              <template slot="suffix">千瓦</template>       
            </el-input>
          </el-form-item>
        </el-form>
        <!-- <div style="text-align:right">
          <el-button size="small" :loading="updataLoading" type="primary" @click="updataPowerDetail('formData')">保 存</el-button>
        </div> -->
    </div>
</template>
<script>
import api from '@/apis/device'

export default {
  data() {
    return {
      updataLoading:false,//按钮状态
      formData:{
        runTime:'',//设计日运行时长
        point:'',//是否重点用能设备
        energyType:'',//消耗能源类别
        powerRated:'',//额定功率
        powerLoad:'',//满载功率
        powerIdler:'',//空载功率
        powerStandby:'',//待机功率
        type:1,
      },
      rules: {
        runTime: [
          { required: true, message: '请选择运行时长', trigger: 'change' },
        ],
        point: [
          { required: true, message: '请选择是否为重点设备', trigger: 'change' }
        ],
        energyType: [
          { required: true, message: '请选择能源类别', trigger: 'change' }
        ],
        powerRated: [
          { required: true, message: '请输入满载时功率', trigger: 'blur' },
        ],
        powerLoad: [
          { required: true, message: '请输入额定功率', trigger: 'blur' },
        ],
        powerIdler: [
          { required: true, message: '请输入空载功率', trigger: 'blur' },
        ],
        powerStandby: [
          { required: true, message: '请输入待机功率', trigger: 'blur' },
        ],
      },
      // permissions:[],//权限
    }
  },
  methods:{
    //获取能耗信息
    getPowerDetails(id){
      api.getDevicePower(id).then(res=>{
        if(res.code==10000){
          if(res.data){
            this.formData = res.data
          }
        }
      })
    },
    //修改能耗信息
    updataPowerDetail(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updataLoading = true;
          this.formData.type = 1
          api.updateDevicePower(this.formData).then(res=>{
            if(res.code==10000){
              this.$message.success('保存成功');
              this.updataLoading = false;
            }else{
              this.$message.error(res.message);
              this.updataLoading = false;
              return;
            }
          })
        } else {
          return false;
        }
      });
    },
  },
  mounted(){
    let _id = sessionStorage.getItem('capID')
    if(_id){
      // this.permissions = JSON.parse(localStorage.getItem('permissions'))
      // if(this.permissions.includes('custom:device:list')){
        this.getPowerDetails(_id)
      // }else{
      //   this.$message.error('没有权限');
      //   return;
      // }
    }
  },
}
</script>
<style lang="less">
.energymsg{ width: 600px; padding-top: 20px;
   .el-input__suffix{ font-size: .8rem;}
   .el-input__inner{ border: none;}
   .el-select .el-input .el-select__caret{ display: none;}
}
</style>
