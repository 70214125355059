<template>
  <div class="device-setup">
    <!-- <div class="top-company-check">
      <el-select @clear="clearCompany" @change="companySelect" clearable filterable v-model="companyValue" placeholder="请先选择企业">
        <el-option
          v-for="item in optionsCompany"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button size="small" type="primary">确 定</el-button>
    </div> -->
    <el-container class="main_box">
      <el-aside v-if="isCompany">
        <div class="device-title">设备列表</div>
        <!-- 屏蔽企业 -->
        <!-- <el-select disabled @clear="clearCompany" @change="companySelect" clearable filterable v-model="companyValue" placeholder="请先选择企业">
          <el-option
            v-for="item in optionsCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select> -->
        <el-select v-if="!companyActive" @clear="clearListDevName" clearable ref="configSelectList" v-model="listDevMsg.name" placeholder="请选择设备类型">
          <el-option style="height:auto;padding:0" value="devicetree">
            <el-tree 
            :data="leftData" 
            :props="leftDefaultProps" 
            :expand-on-click-node="false"
            :default-expand-all="true"
            @node-click="handleNodeClick">
            </el-tree>
          </el-option>
        </el-select>
        <el-input
          v-if="!companyActive"
          placeholder="请输入搜索内容"
          prefix-icon="el-icon-search"
          @input="searchInput"
          v-model="searchDevice">
        </el-input>
        <div v-if="leftListData.length==0" style="margin:8px 0;text-align:center;color:#aaa">暂无设备</div>
        <ul v-else class="dev-left-list">
          <li @click="lefeListClick(item,index)" :class="leftActive==index||companyActive==item.id?'active':''" v-for="(item,index) in leftListData" :key="index+'l1'">
            <span>{{item.name}}</span>
            <!-- <el-tag :type="item.status==1?'':'danger'" size="mini">{{item.status==1?'正常':'异常'}}</el-tag> -->
          </li>
        </ul>
      </el-aside>
      <el-container class="main_right">
        <!-- <el-header> -->
          <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="1">
              <BasicMsg :devID="devID" :companyId="companyValue" @isDelDev="renewList" v-if="activeName=='1'"></BasicMsg>
            </el-tab-pane>
            <el-tab-pane v-if="isProductionDev" label="能耗信息" name="2">
              <EnergyMsg v-if="activeName=='2'"></EnergyMsg>
            </el-tab-pane>
            <el-tab-pane v-if="isProductionDev" label="生产记录" name="3">
              <ProductionMsg v-if="activeName=='3'" :companyId="companyValue"></ProductionMsg>
            </el-tab-pane>
            <el-tab-pane v-if="isProductionDev" label="运行记录" name="4">
              <WorkingMsg v-if="activeName=='4'"></WorkingMsg>
            </el-tab-pane>
            <el-tab-pane label="运维记录" name="5">
              <OperateMsg v-if="activeName=='5'" :companyId="companyValue"></OperateMsg>
            </el-tab-pane>
            <el-tab-pane label="关联表计" name="6">
              <Relation v-if="activeName=='6'"></Relation>
            </el-tab-pane>
          </el-tabs>
          <!-- <div class="device-setup-button">
            <el-button size="small" type="success" icon="el-icon-plus" @click="addNewDev">添加设备</el-button>
            <el-button type="primary">批量导入</el-button>
          </div> -->
        <!-- </el-header> -->
      </el-container>
    </el-container>
    <el-dialog
      title="新建设备信息"
      v-dialogDrag
      @close="addDevCloseForm"
      :close-on-click-modal="false"
      :visible.sync="addDeviceDialog"
      top="5vh"
      width="55%">
      <div class="subtitle" style="margin-top:0">分类信息</div>
      <div>
        <span style="margin:0 15px 0 50px">请选择企业</span>
        <span>
          <el-select @change="companyValueAddChange" clearable filterable v-model="companyValueAdd" style="width:30%" placeholder="请选择企业">
            <el-option
              v-for="item in optionsCompany"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </span>
        <span style="margin:0 15px 0 50px">请选择设备类别</span>
        <span>
          <el-cascader
            clearable
            style="line-height:0"
            :props="{ value: 'id',label:'name',children:'children'}"
            :options="options"
            ref="deviceTypeTree"
            :show-all-levels="false"
            @change="deviceChange"
          ></el-cascader>
        </span>
      </div>
      <div class="subtitle">基本信息</div>
      <!-- 基本信息form -->
      <el-form :model="ruleForm" ref="ruleForm" :rules="baseInfoRule" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="name">
              <el-input show-word-limit maxlength="20" type="text" v-model="ruleForm.name" placeholder="请输入设备名称"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="设备类型" prop="type">
              <el-select v-model="ruleForm.type" placeholder="请选择设备类型">
                <el-option label="单台设备" :value="0"></el-option>
                <el-option label="生产线" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="设备品牌" prop="brand">
              <el-input show-word-limit maxlength="20" type="text" v-model="ruleForm.brand" placeholder="请输入设备品牌"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备型号" prop="marking">
              <el-input show-word-limit maxlength="20" type="text" v-model="ruleForm.marking" placeholder="请输入设备型号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安装区域" prop="area">
              <el-select ref="configSelectAdd" clearable v-model="ruleForm.area" placeholder="请选择安装区域">
                <el-option style="height:auto;padding:0 15px;background:#fff;" value="xxxxxxx">
                  <el-tree
                    node-key="areaId"
                    ref="area"
                    default-expand-all
                    :data="areaTreeData"
                    :props="defaultPropsArea"
                    :check-on-click-node="true"
                    :expand-on-click-node='false'
                    :check-strictly="true"
                    @check-change="handleCheckChangeArea"
                  >
                  </el-tree>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="安装位置" prop="address">
              <el-row>
                <el-col :span="20">
                  <el-input ref="anZhuangWeiZhi" clearable v-model="ruleForm.address" placeholder="请输入安装位置"></el-input>
                </el-col>
                <el-col :span="4" style="text-align:right;">
                  <el-button size="small" type="primary" @click="openBiaozhu">地图标注</el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <!-- 经纬度 -->
          <el-col :span="12">
            <el-form-item label="经度" prop="longitude">
              <el-input v-model="ruleForm.longitude" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度" prop="latitude">
              <el-input v-model="ruleForm.latitude" disabled></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="负责人" prop="linkUser">
              <el-select clearable filterable v-model="ruleForm.linkUser" placeholder="请选择负责人">
                <el-option 
                  v-for="(user,i) in principalList" 
                  :key="i" 
                  :label="user.name" 
                  :value="user.userId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="制造日期" prop="outTime">
              <el-date-picker
                v-model="ruleForm.outTime"
                value-format="yyyy-MM-dd hh:mm:ss"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安装日期" prop="installTime">
              <el-date-picker
                v-model="ruleForm.installTime"
                value-format="yyyy-MM-dd hh:mm:ss"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="售后截止日期" prop="serviceTime">
              <el-date-picker
                v-model="ruleForm.serviceTime"
                value-format="yyyy-MM-dd hh:mm:ss"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="制造厂商" prop="firm">
              <el-input v-model="ruleForm.firm" placeholder="请输入制造厂商"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用状态" prop="useStatus">
              <el-select v-model="ruleForm.useStatus" placeholder="请选择使用状态">
                <el-option label="正常自用" :value="1"></el-option>
                <el-option label="闲置" :value="2"></el-option>
                <el-option label="待出租" :value="3"></el-option>
                <el-option label="出租中" :value="4"></el-option>
                <el-option label="出售中" :value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运行状态" prop="runStatus">
              <el-select v-model="ruleForm.runStatus" placeholder="请选择运行状态">
                <el-option label="正常" :value="1"></el-option>
                <el-option label="关机" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保养周期" prop="maintain">
              <el-input type="number" v-model="ruleForm.maintain" placeholder="请输入保养周期">
                <template slot="suffix">日/次</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维保单位" prop="construct">
              <el-select v-model="ruleForm.construct" placeholder="请选择使用状态">
                <el-option label="厂家售后" value="1"></el-option>
                <el-option label="自行维保" value="2"></el-option>
                <el-option label="维保外包" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item v-if="ruleForm.type==1" label="子设备" prop="children">
              <el-select multiple collapse-tags value-key="id" @change="((value)=>{childDevChange(value)})" v-model="ruleForm.children" placeholder="请选择子设备">
                <el-option v-for="(item,index) in leftListData" :key="index" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12" v-for="(mod,index) in moduleData" :key="index">
            <!-- 每个设备特定模型 -->
            <el-form-item 
              :label="mod.propertyName" 
              :prop="'propertyFiled.'+index"
              :rules="{
                required: true, 
                message: mod.propertyInput=='select'?'请选择'+mod.propertyName:'请输入'+mod.propertyName,  
                trigger: mod.propertyInput=='select'?'change':'blur'
              }">
              <el-select 
                value-key="id" 
                @change="((value)=>{propertyChange(value,mod,index)})" 
                v-if="mod.propertyInput=='select'" 
                v-model="ruleForm.propertyFiled[index]" 
                placeholder="请选择">
                <el-option v-for="(item,dex) in mod.resourceValue?JSON.parse(mod.resourceValue):[]" :key="dex" :label="item" :value="item"></el-option>
              </el-select>
              <el-input 
                @change="propertyInput(index,mod,ruleForm.propertyFiled[index])" 
                v-else 
                :type="mod.propertyType=='number'?'number':'text'" 
                v-model="ruleForm.propertyFiled[index]" 
                :placeholder="'请输入'+mod.propertyName">
                <template slot="suffix">{{mod.unit}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="subtitle">设备图片</div>
            <el-form-item label="设备图片" prop="pictures">
              <el-upload
                action="/"
                :http-request="updateImg"
                list-type="picture-card"
                :before-upload="beforeAvatarUpload"
                :before-remove="beforeRemovePic"
                :file-list="showPicList"
                :limit="10"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <div class="subtitle">设备图片</div> -->
      </el-form>
      <div v-if="isProductionDevAdd" class="subtitle">能耗信息</div>
      <!-- 能耗信息form -->
      <el-form v-if="isProductionDevAdd" :rules="powerInfoRule" :model="powerForm" ref="powerForm" label-width="140px" class="demo-ruleForm">
        <el-row>
          <el-form-item label="设计日运行时长">
            <el-select style="width:80%" v-model="powerForm.runTime" placeholder="请选择">
              <el-option v-for="(item,index) in 24" :key="index" :label="item" :value="item"></el-option>
            </el-select>
            <span> 小时</span>
          </el-form-item>
          <el-col :span="12">
            <el-form-item label="是否重点用能设备">
              <el-select v-model="powerForm.point" placeholder="请选择">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="消耗能源类别">
              <el-select v-model="powerForm.energyType" placeholder="请选择">
                <el-option label="电力" :value="1"></el-option>
                <el-option label="水力" :value="2"></el-option>
                <el-option label="燃气" :value="3"></el-option>
                <el-option label="燃油" :value="4"></el-option>
                <el-option label="燃煤" :value="5"></el-option>
                <el-option label="蒸汽" :value="6"></el-option>
                <el-option label="其他" :value="7"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="额定功率">
              <el-input type="number" v-model="powerForm.powerRated" placeholder="请输入额定功率">
                <template slot="suffix">千瓦</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="满载功率">
              <el-input type="number" v-model="powerForm.powerLoad" placeholder="请输入满载功率">
                <template slot="suffix">千瓦</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="空载功率">
              <el-input type="number" v-model="powerForm.powerIdler" placeholder="请输入空载功率">
                <template slot="suffix">千瓦</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="待机功率">
              <el-input type="number" v-model="powerForm.powerStandby" placeholder="请输入待机功率">
                <template slot="suffix">千瓦</template>       
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" :loading="saveDevLoading" type="primary" @click="saveNewDevice('ruleForm','powerForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 百度地图弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      top="5vh"
      v-dialogDrag
      :modal="false"
      class="bmap-dialog"
      id="dituDialogDetails"
      title="标注坐标"
      :visible.sync="dialogVisible"
      width="60%">
      <div class="map-content">
        <baidu-map class="map_baidu" :center="map.center" :zoom="map.zoom" @ready="handler">
          <bm-boundary :name="contour" :fillOpacity="0.1" :strokeWeight="2" strokeColor="blue"></bm-boundary>
          <bm-local-search :location="location" :keyword="keyword" :auto-viewport="true"></bm-local-search>
          <!--点-->
          <bm-marker @click="infoWindowOpen" :position="map.position" :dragging="map.dragging" animation="BMAP_ANIMATION_DROP">
          </bm-marker>
          <!-- 定位 -->
          <!-- <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation> -->
        </baidu-map>
      </div>
      <span slot="footer" class="dialog-footer">
        <label>关键词搜索：<input style="margin-right:1.42em;height:1.72rem;padding-left:4px;" v-model="keyword"></label>
        <el-button size="mini" type="primary" @click="getLatLng">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import BasicMsg from './basicMsg'
import EnergyMsg from './energyMsg'
import ProductionMsg from './productionMsg'
import WorkingMsg from './workingMsg'
import OperateMsg from './operateMsg'
import Relation from './relation'
import api from '@/apis/device'
// import area from '@/apis/power/monitor'
import load from '@/apis/upload'
export default {
  props:['companyDevID'],
  components:{
    BasicMsg,EnergyMsg,ProductionMsg,WorkingMsg,OperateMsg,Relation
  },
  data(){
    return{
      companyActive:'',//左侧默认选择样式id
      //地图
      dialogVisible:false,//地图弹框
      contour:'',//区域轮廓默认德阳
      location:'',//默认地区
      keyword:'',//关键词搜索
      map:{//百度地图数据
        center:'',
        zoom: 12,
        show: false,
        dragging: true,
        position:{lng:'',lat:''}//定点
      },
      // 上传
      showPicList:[],//回显图片信息
      temPicList:[],//保存图片信息
      //按钮加载状态
      saveDevLoading:false,
      // 左侧
      listDevMsg:{
        name:'',
        id:''
      },//左侧列表设备模型名称
      searchDevice:'',//左侧搜索栏
      leftListData:'',//左侧列表设备数据
      isProductionDev:'',//左侧列表判断是否是生产设备
      devID:'',//左侧点击传到子组件id
      leftActive:-1,//左侧点击后li样式
      //头部-标签切换
      activeName: '1',
      optionsCompany:'',//所有公司数据
      companyValue:'',//选中公司id
      // 新增设备
      addDeviceDialog:false,//新增设备弹框
      isProductionDevAdd:false,//新增设备弹框
      companyValueAdd:'',//新增选择企业
      options:[],//设备类别数据
      moduleData:'',//特定模型数据
      areaTreeData:[],//区域树数据
      defaultPropsArea: {
        label: "areaName",
        children: "companyAreasDtos",
        areaId:'areaId'
      },
      savePropertyFiled:[],//特殊模型临时数据select
      savePropertyFiledInp:[],//特殊模型临时数据input
      childrenDevData:'',//子设备临时数据
      principalList:'',//负责人数据
      //form数据-生产线
      ruleFormLine:{
        name: '',//名称
        area: '',//安装区域
        address: '',//安装位置
        linkUser: '',//负责人
      },
      //form数据
      ruleForm: {
        name: '',//设备名称
        // type: '',//设备类型
        brand: '',//设备品牌
        marking: '',//设备型号
        area: '',//安装区域
        address: '',//安装位置
        longitude: '',//经度
        latitude: '',//纬度
        linkUser: '',//负责人
        outTime: '',//制造日期
        installTime: '',//安装日期
        serviceTime: '',//售后截止日期
        firm: '',//制造厂商
        useStatus: '',//使用状态
        runStatus: '',//运行状态
        maintain:'',//保养周期
        construct:'',//维保单位
        pictures:[],//设备图片
        propertyFiled:[],//模型json数据
        // children:[],//子设备
      },
      powerForm:{//能耗信息
        runTime:'',//设计日运行时长
        point:'',//是否重点用能设备
        energyType:'',//消耗能源类别
        powerRated:'',//额定功率
        powerLoad:'',//满载功率
        powerIdler:'',//空载功率
        powerStandby:'',//待机功率
      },
      baseInfoRule:{//基本信息验证规则
        name: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        // type: [
        //   { required: true, message: '请选择设备类型', trigger: 'change' }
        // ],
        brand: [
          { required: true, message: '请输入设备品牌', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        marking: [
          { required: true, message: '请输入设备型号', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '请选择安装区域', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入安装位置', trigger: 'blur' },
        ],
        longitude: [
          { required: true, message: '请在地图上标注', trigger: 'blur' },
        ],
        latitude: [
          { required: true, message: '请在地图上标注', trigger: 'blur' },
        ],
        linkUser: [
          { required: true, message: '请选择负责人', trigger: 'change' }
        ],
        outTime: [
          { required: true, message: '请选择制造日期', trigger: 'change' }
        ],
        installTime: [
          { required: true, message: '请选择安装日期', trigger: 'change' }
        ],
        serviceTime: [
          { required: true, message: '请选择售后截止日期', trigger: 'change' }
        ],
        firm: [
          { required: true, message: '请输入制造厂商', trigger: 'blur' },
        ],
        useStatus: [
          { required: true, message: '请选择使用状态', trigger: 'change' }
        ],
        runStatus: [
          { required: true, message: '请选择运行状态', trigger: 'change' }
        ],
        maintain: [
          { required: true, message: '请输入保养周期', trigger: 'blur' },
        ],
        construct: [
          { required: true, message: '请选择维保单位', trigger: 'change' }
        ],
        // children: [
        //   { required: true, message: '请选择子设备', trigger: 'change' }
        // ],
      },
      powerInfoRule:{//能耗信息验证规则
        runTime: [
          { required: true, message: '请选择日运行时长', trigger: 'change' }
        ],
        point: [
          { required: true, message: '请选择是否重点用能设备', trigger: 'change' },
        ],
        energyType: [
          { required: true, message: '请选择能源类别', trigger: 'change' }
        ],
        powerRated: [
          { required: true, message: '请选择额定功率', trigger: 'blur' }
        ],
        powerLoad: [
          { required: true, message: '请输入满载功率', trigger: 'blur' },
        ],
        powerIdler: [
          { required: true, message: '请输入空载功率', trigger: 'blur' }
        ],
        powerStandby: [
          { required: true, message: '请输入待机功率', trigger: 'blur' }
        ],
      },

      //左侧设备类型数据
      leftData:[],
      leftDefaultProps: {
        children: 'children',
        label: 'name'
      },
      
      isCompany:''
      // permissions:[],//权限
    }
  },
  watch:{
    companyDevID:{
      handler(newVal){
        let _that = this
        if(newVal.constructor===Object){
          _that.companyValue = newVal.companyId
          sessionStorage.setItem('capID',newVal.id)
          _that.companyActive = newVal.id
          _that.devID = newVal.id
          this.activeName = '1'
          _that.isCompany = false
          this.onlyProduction(newVal.modeId)
        }else{
          _that.companyValue = newVal
          _that.companyActive = ''
          _that.leftActive = -1
          sessionStorage.removeItem('capID')
          _that.isCompany = true
          this.activeName = '1'
          _that.devID = ''
          _that.getDevList()
        }
      },
      deep:true,
    },
  },
  methods:{
    //地图api
    //展开详情
    infoWindowOpen(){
      this.map.show = true;
    },
    //点击确定获取经纬度
    getLatLng(){
      if(!this.ruleForm.address){
        this.$confirm('未选取坐标, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dialogVisible = false
          this.$refs.anZhuangWeiZhi.focus()
        })
      }else{
        this.dialogVisible = false
        this.$refs.anZhuangWeiZhi.focus()
      }
    },
    //打开地图标注
    openBiaozhu(){
      this.dialogVisible = true;
      this.map.center = '德阳'
      this.contour = ''
      this.location = '德阳'
    },
    //百度加载完毕方法
    handler ({BMap,map}) {
      let _this = this;
      //定位
      // var geolocation = new BMap.Geolocation();
      // geolocation.getCurrentPosition(function(r){
      //   // var mk = new BMap.Marker(r.point);
      //   // map.addOverlay(mk);
      //   map.panTo(r.point);
      // },{enableHighAccuracy: true})

      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener('click', function (e) {
        _this.map.position.lng = e.point.lng
        _this.map.position.lat = e.point.lat
        _this.ruleForm.longitude = e.point.lng
        _this.ruleForm.latitude = e.point.lat
        var point = new BMap.Point(e.point.lng, e.point.lat);
        var gc = new BMap.Geocoder();
        gc.getLocation(point, function (rs) {
          _this.ruleForm.address = 
            rs.addressComponents.province + '-' +
            rs.addressComponents.city + '-' +
            rs.addressComponents.district
          if(rs.addressComponents.street){
            _this.ruleForm.address = _this.ruleForm.address + '-' + rs.addressComponents.street
          }
          if(rs.addressComponents.streetNumber){
            _this.ruleForm.address = _this.ruleForm.address + '-' + rs.addressComponents.streetNumber
          }
        })
      })
    },
    //左侧顶部选择企业
    companySelect(){
      //获取设备列表
      if(this.companyValue){
        sessionStorage.clear()
        this.activeName = '1'
        this.devID = ''
        this.leftActive = -1
        this.isProductionDev = false;
        this.getDevList()
      }
    },
    //左侧顶部点击清空设备
    clearCompany(){
      this.companyValue = ''
      sessionStorage.clear()
      this.activeName = '1'
      this.devID = ''
      this.leftActive = -1
      this.isProductionDev = false;
      this.getDevList()
    },
    //左侧判断是否为生产设备
    onlyProduction(id){
      api.isProduction(id).then(res=>{
        if(res.code==10000){
          this.isProductionDev = res.data
        }
      })
    },
    //左侧设备类别切换
    handleNodeClick(data){
      if(!this.companyValue){
        this.$message.error('请选择企业');
        return;
      }
      sessionStorage.clear()
      this.activeName = '1'
      this.leftActive = -1
      this.devID = ''
      this.$refs.configSelectList.blur()
      this.listDevMsg = {
        name:data.name,
        id:data.id,
      }
      this.getDevList()
      this.onlyProduction(data.id)
    },
    //左侧设备列表点击-父传子
    lefeListClick(msg,_num){
      this.companyActive = ''
      this.leftActive = _num
      this.activeName = '1'
      sessionStorage.setItem('capID',msg.id)
      this.$nextTick(()=>{
        this.devID = msg.id
      })
      sessionStorage.setItem('modeID',msg.mode)
      this.onlyProduction(msg.mode)
    },
    //清空按钮触发
    clearListDevName(){
      this.listDevMsg = {
        name:'',
        id:'',
      }
      sessionStorage.clear()
      this.activeName = '1'
      this.devID = ''
      this.leftActive = -1
      this.isProductionDev = false;
      this.getDevList()
    },
    //搜索输入框事件
    searchInput(){
      this.leftActive = -1
      this.getDevList()
    },
    //子传父 更新列表
    renewList(status){
      if(status){
        this.leftActive = -1
        this.getDevList()
      }else{
        this.getDevList()    
      }
    },
    //区域选择事件
    handleCheckChangeArea(val) {
      this.ruleForm.area = val.areaName
      this.ruleForm.areaId = val.areaId
      this.$refs.configSelectAdd.blur()
    },
    //子设备选择事件
    childDevChange(val){
      let _arr = val.map(item=>{
        return{
          devChildName:item.name,
          devChildId:item.id,
        }
      })
      this.childrenDevData = _arr
    },


    //获取设备类别-设备模型
    getDeviceModule(){
      api.getModuleInfo().then(res=>{
        if(res.code==10000){
          if(res.data.length!=0){
            this.options = res.data
          }else{
            this.$message.error('获取设备模型树失败');
            return;
          }
        }
      })
    },
    //获取安装区域树结构
    recursiveFunction() {
      api.findCapitalAreas(this.companyValueAdd).then((res) => {
        if (res.code == 10000) {
          this.areaTreeData = res.data;
        }
      });
    },
    //获取负责人
    getUserList(){
      api.getAllUser(this.companyValueAdd).then(res=>{
        if(res.code==10000){
          if(res.data.length!=0){
            this.principalList = res.data
          }else{
            this.principalList = []
          }
        }else{
          this.$message.error(res.message);
          return;
        }
      })
    },
    

    //关闭新增框清空表单
    addDevCloseForm(){
      this.showPicList = []      
      this.ruleForm.areaId = ''
      this.ruleForm.modeId = ''
      this.$refs.deviceTypeTree.$refs.panel.clearCheckedNodes()//清空级联
      //重置表单
      this.$refs.ruleForm.resetFields();
      if(this.isProductionDevAdd){
        this.$refs.powerForm.resetFields();
      }
    },
    //新增设备弹框
    addNewDev(){
      this.addDeviceDialog = true;
      //试了很多办法没法清空只能这样
      setTimeout(()=>{
        this.ruleForm.area = ''
        this.$refs.ruleForm.resetFields();
      },100)
      this.getDeviceModule()//获取级联树
      // this.getUserList()//获取负责人
      // this.recursiveFunction()//获取安装区域树
    },
    //新增选择企业获取安装区域树
    companyValueAddChange(){
      this.getUserList()//获取负责人
      this.recursiveFunction()//获取安装区域树
    },
    //选择设备类型级联并获取特定模型-新增
    deviceChange(val){
      this.$refs.ruleForm.resetFields();
      if(this.isProductionDevAdd){
        this.$refs.powerForm.resetFields();
      }
      var _id = val.slice(-1).toString()
      this.ruleForm.modeId = _id
      api.isProduction(_id).then(res=>{
        if(res.code==10000){
          this.isProductionDevAdd = res.data
        }
      })
      api.getModuleList(_id).then(res=>{
        if(res.code==10000){
          if(res.data.length!=0){
            this.moduleData = res.data
          }else{
            this.moduleData = []
          }
        }
      })
    },
    //特定设备模型选择框切换事件
    propertyChange(msg,name,index){
      let obj = {
        name :name.propertyName,
        [name.propertyRow]:msg,
        sub:index,
      }
      this.savePropertyFiled[index] = obj
    },
    //特定设备模型输入事件
    propertyInput(index,msg,num){
      let _obj = {
        name:msg.propertyName,
        [msg.propertyRow]:num,
        sub:index,
      }
      this.savePropertyFiledInp[index] = _obj
    },
    //获取设备列表
    getDevList(){
      api.getDeviceList({
        companyId:this.companyValue,
        name:this.searchDevice,
        page:1,
        id:this.listDevMsg.id,
        limit:1000
      }).then(res=>{
        if(res.code==10000){
          let _data = res.data.list
          let _arr = _data.map(item=>{
            return{
              id:item.id,
              name:item.name,
              mode:item.modeId,
              status:item.runStatus,
            }
          })
          this.leftListData = _arr
        }
      })    
    },
    //保存并新增设备
    saveNewDevice(formName,formName2){
      if(!this.companyValueAdd){
        this.$message.warning('请选择企业');
        return;
      }else{
        this.ruleForm.companyId = this.companyValueAdd
      }
      //保存能耗信息
      if(this.isProductionDevAdd){
        this.$refs[formName2].validate((valid) => {
          if (valid) {
            this.ruleForm.deviceEnergies = this.powerForm
          } else {
            return;
          }
        });
      }
      //保存基本信息
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveDevLoading = true;
          if(this.ruleForm.propertyFiled.length==0){
            this.ruleForm.propertyFiled = ''
          }else{
            for(var i in this.ruleForm.propertyFiled){
              for(var j in this.savePropertyFiled){
                if(i==j){
                  this.ruleForm.propertyFiled[i] = this.savePropertyFiled[j]
                }
              }
              for(var k in this.savePropertyFiledInp){
                if(i==k){
                  this.ruleForm.propertyFiled[i] = this.savePropertyFiledInp[k]
                }
              }
            }        
            this.ruleForm.propertyFiled = JSON.stringify(this.ruleForm.propertyFiled)
          }
          if(!this.ruleForm.modeId){
            this.$message.error('请选择设备类别');
            this.saveDevLoading = false;
            return;
          }
          // if(!this.ruleForm.areaId){
          //   this.$message.error('请选择区域');
          //   return;
          // }
          if(new Date(this.ruleForm.outTime).getTime()>new Date(this.ruleForm.installTime).getTime()){
            this.$message.error('制造日期不能大于安装日期');
            this.saveDevLoading = false;
            return;
          }
          if(this.ruleForm.pictures.length!=0){
            this.ruleForm.pictures = JSON.stringify(this.ruleForm.pictures)
          }else{
            this.ruleForm.pictures = ''
          }
          // if(this.ruleForm.type==1){
          //   this.ruleForm.children = this.childrenDevData
          // }
          api.saveDeviceInfo(this.ruleForm).then(res=>{
            if(res.code==10000){
              this.$message.success('新增成功');
              this.getDevList()
              this.addDeviceDialog = false
              this.saveDevLoading = false;
            }else{
              this.$message.error(res.message);
              this.saveDevLoading = false;
              return;
            }
          })
        } else {
          return false;
        }
      });
    },





    // 上传图片
    updateImg(val) {
      let fd = new FormData();
      fd.append("file", val.file);
      load.upload(fd).then((res) => {
        if (res.code == 10000) {
          this.ruleForm.pictures.push({
            name: val.file.name,
            url: res.data,
            uid: val.file.uid,
          });
          // 显示图片
          this.showPicList.push({
            name: val.file.name,
            url: res.data,
            uid: val.file.uid,
          });
          this.$message({
            message: "上传成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
          return;
        }
      });
    },
    //判断上传文件格式是否合法
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 删除图片
    beforeRemovePic(file) {
      return this.$confirm(`确定移除 ${file.name}？`).then(() => {
        this.showPicList.map((item, index) => {
          if (item.uid == file.uid) {
            // 删除
            api.delUploadImg({ filePath: item.url }).then((res) => {
              if (res.code == 10000) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.showPicList.splice(index, 1);
                this.ruleForm.pictures.splice(index, 1);
              } else {
                this.$message({
                  message: res.message,
                  type: "warning",
                });
                return;
              }
            });
          }
        });
      });
    },
  },
  created(){
    sessionStorage.clear()//页面切换清空记录
  },
  mounted(){
    //权限
    // this.permissions = JSON.parse(localStorage.getItem('permissions'))
    //监听刷新
    // window.addEventListener("beforeunload", ()=> {
      //  sessionStorage.clear()
    // });
    if(sessionStorage.getItem('modeID')){
      this.onlyProduction(sessionStorage.getItem('modeID'))
    }
    //获取所有企业
    api.getAllCompany().then(data=>{
      if(data.code==10000){
        this.optionsCompany = data.data
        if(this.companyDevID.constructor===Object){
          this.companyValue = this.companyDevID.companyId
          this.companyActive = this.companyDevID.id
          sessionStorage.setItem('capID',this.companyDevID.id)
          this.isCompany = false
          this.onlyProduction(this.companyDevID.modeId)
          this.devID = this.companyDevID.id
        }else{
          this.companyValue = this.companyDevID
          this.isCompany = true
          // this.companyActive = ''
          // this.devID = ''
        }
        this.getDevList()
      }
    })
    //获取左侧设备类别
    api.getModuleInfo().then(res=>{
      if(res.code==10000){
        if(res.data.length!=0){
          this.leftData = res.data
        }else{
          this.$message.error('获取设备模型树失败');
          return;
        }
      }
    })
  },
}
</script>
<style lang="less">
.device-setup{ 
  height: 60rem; 
  width: 100%;
  overflow: hidden;
  .main_box{ display: flex; flex-direction: row; height: 100%; overflow: hidden; width: 100%;
    .el-aside{ height: 100%; width: 16rem !important; flex-shrink: 0; border: #005ae4 solid 1px; margin-right:1rem; display: flex; flex-direction: column; overflow: hidden;
      .device-title{ text-align: center; height: 40px; border-bottom: solid 1px #005ae4; line-height: 40px;}
      &>.el-select{ margin:10px 10px 0; width: -webkit-fill-available;}
      &>.el-input{ margin:10px; width: -webkit-fill-available;}
      .dev-left-list{ flex: 1; height: 100%; overflow-y: auto; padding:0 10px;
        li{ display: flex; flex-direction: row; justify-content: space-between; cursor: pointer; align-items: center; padding:5px; margin-bottom: 2px;}
        li:hover{ background: #005ae4;}
        li.active{ color: #fff; background: #005ae4;}
      }
    }
    .main_right{ flex: 1; width: 100%; height: 100%; overflow: hidden; position: relative;
      .el-tabs{ flex: 1; width: 100%; height: 100%; overflow: hidden; display: flex; flex-direction: column;
        .el-tabs__nav{ margin-left: 20px;}
        .el-tabs__header{ margin-bottom: 1rem;}
        .el-tabs__content{ height: 100%; flex: 1;
          &>div{ height: 100%; flex: 1; overflow-y: auto; display: flex; flex-direction: column;}
        }
      }
    }
  }
  .el-input.is-disabled .el-input__inner{
    background-color: transparent; 
    // border-color: transparent;
    // color: #005ae4;
    cursor: default;
  }
}
</style>