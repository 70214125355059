<template>
    <div class="production">
      <!-- <div class="search">
        <div class="pull_left"></div>
        <div class="pull_right">
          <el-button size="small" type="success" icon="el-icon-plus" @click="dialogVisible=true;getCompany()">添加记录</el-button>
          <el-button size="small" type="info" icon="el-icon-s-promotion" @click="devOperate">导出</el-button>
        </div>
      </div> -->
      <div class="tablebox" id="devOperaData">
        <el-table :data="tableData" style="width: 100%" border height="100%">
          <el-table-column align="center" prop="opsTime" label="日期" width="200"></el-table-column>
          <el-table-column align="center" prop="opsType" label="维保类型" width="220"></el-table-column>
          <el-table-column align="center" prop="opsConstruct" label="维保单位"></el-table-column>
          <el-table-column align="center" label="维保人员" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.opsUserName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="维保结果" width="180"></el-table-column>
          <el-table-column show-overflow-tooltip prop="remark" label="备注"></el-table-column>
          <!-- <el-table-column align="center" label="操作" width="160">
            <template slot-scope="scope">
              <el-button @click="handleClickEdit(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="handleClickDel(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="pagebox">
        <el-pagination 
          @current-change="elCurrentChange" 
          :current-page="currentPage" background=""
          @size-change="handleSizeChange"
          :page-size="pageSize" 
          layout="total,sizes, prev, pager, next" 
          :total="totalCount">
        </el-pagination>
      </div>

    <!-- 新增记录 -->
    <el-dialog 
      title="添加记录" 
      v-dialogDrag
      @close="clearForm"
      :visible.sync="dialogVisible" 
      :close-on-click-modal="false"
      width="50%">
      <div class="form_dialog">
        <el-form ref="runForm" :model="runForm" label-width="120px" :rules="rules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择日期" prop="opsTime">
                <el-date-picker 
                  v-model="runForm.opsTime" 
                  value-format="yyyy-MM-dd hh:mm:ss"
                  type="date" 
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保类型" prop="opsType">
                <el-select v-model="runForm.opsType" placeholder="请选择">
                  <el-option label="例行巡检" :value="1"></el-option>
                  <el-option label="维修" :value="2"></el-option>
                  <el-option label="保养" :value="3"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保单位" prop="opsConstruct">
                <el-select @change="runForm.opsUser='';opsCompanyChange(runForm.opsConstruct);" clearable filterable v-model="runForm.opsConstruct" placeholder="请选择">
                  <el-option label="企业内部" value="0"></el-option>
                  <el-option v-for="(item,x) in addCompanyList" 
                    :key="x"
                    :label="item.operationCompanyName" 
                    :value="item.operationCompanyId">
                  </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保人员" prop="opsUser">
                <el-select filterable clearable v-model="runForm.opsUser" placeholder="请选择">
                  <el-option 
                    v-for="(item,ind) in userList" 
                    :key="ind"
                    :label="item.name" 
                    :value="item.userId">
                  </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保结果" prop="status">
                <el-select v-model="runForm.status" placeholder="请选择">
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="异常" :value="0"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input maxlength="200" show-word-limit type="textarea" v-model="runForm.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" :loading="saveLoading" type="primary" @click="saveOperate('runForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑记录 -->
    <el-dialog 
      title="编辑记录" 
      v-dialogDrag
      :visible.sync="dialogVisibleEdit" 
      :close-on-click-modal="false"
      width="50%">
      <div class="form_dialog">
        <el-form ref="editForm" :model="editForm" label-width="120px" :rules="rules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择日期" prop="opsTime">
                <el-date-picker 
                  v-model="editForm.opsTime" 
                  value-format="yyyy-MM-dd hh:mm:ss"
                  type="date" 
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保类型" prop="opsType">
                <el-select clearable v-model="editForm.opsType" placeholder="请选择">
                  <el-option label="例行巡检" :value="1"></el-option>
                  <el-option label="维修" :value="2"></el-option>
                  <el-option label="保养" :value="3"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保单位" prop="opsConstruct">
                <el-select @change="editForm.opsUser='';opsCompanyChange(editForm.opsConstruct);" clearable filterable v-model="editForm.opsConstruct" placeholder="请选择">
                  <el-option label="企业内部" value="0"></el-option>
                  <el-option v-for="(item,x) in addCompanyList" 
                    :key="x"
                    :label="item.operationCompanyName" 
                    :value="item.operationCompanyId">
                  </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保人员" prop="opsUser">
                <el-select filterable clearable v-model="editForm.opsUser" placeholder="请选择">
                  <el-option 
                    v-for="(item,ind) in userList" 
                    :key="ind"
                    :label="item.name" 
                    :value="item.userId">
                  </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保结果" prop="status">
                <el-select clearable v-model="editForm.status" placeholder="请选择">
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="异常" :value="0"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input maxlength="200" show-word-limit type="textarea" v-model="editForm.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisibleEdit = false">取 消</el-button>
        <el-button size="small" :loading="editLoading" type="primary" @click="updataOperate('editForm')">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import api from '@/apis/device'

import common from '@/components/monitorCommon/common'
export default {
  props:['companyId'],
  data() {
    return {
      //分页
      currentPage:1,
      pageSize:20,
      totalCount:0,


      saveLoading:false,//新增按钮状态
      editLoading:false,//编辑按钮状态
      tableData:[],//表格数据
      dialogVisible:false,//新增弹框
      dialogVisibleEdit:false,//编辑弹框

      addCompanyList:'',//获取的维保单位
      //表单数据
      runForm:{//新增
        opsTime:'',
        opsType:'',
        opsConstruct:'',
        opsUser:'',
        status:'',
        remark:'',
      },
      editForm:{//编辑
        opsTime:'',
        opsType:'',
        opsConstruct:'',
        opsUser:'',
        status:'',
        remark:'',
      },
      rules: {//表单验证规则
          opsTime: [
            { required: true, message: '请选择日期', trigger: 'change' },
          ],
          opsType: [
            { required: true, message: '请选择维保类型', trigger: 'change' },
          ],
          opsConstruct: [
            { required: true, message: '请选择维保单位', trigger: 'change' },
          ],
          opsUser: [
            { required: true, message: '请选择维保人员', trigger: 'change' },
          ],
          status: [
            { required: true, message: '请选择维保结果', trigger: 'change' },
          ],
          remark: [
            { required: true, message: '请输入备注', trigger: 'blur' },
          ],
        },
        userList:'',
        // permissions:[],//权限
    }
  },
  methods:{
    //导出数据
    devOperate(){
      if(this.tableData.length==0){
        this.$message.error('暂无数据导出');
        return;
      }else{
        common.outTab('devOperaData','运维记录')
      }  
    },
    //分页
    elCurrentChange(val) {
      this.getList(val,this.pageSize)
    },
    handleSizeChange(val) {
      this.getList(this.currentPage,val)
    },
    //关闭清空新增数据
    clearForm(){
      this.$refs.runForm.resetFields();
    },
    //维保单位切换-新增
    opsCompanyChange(value){
      if(value!='0'){
        api.getMindUser(value).then(res=>{
          if(res.code==10000){
            if(res.data.length!=0){
              this.userList = res.data
            }else{
              this.userList = []
            }
          }
        })
      }else{
        api.getAllUser(this.companyId).then(res=>{
          if(res.code==10000){
            if(res.data){
              this.userList = res.data
            }else{
              this.userList = []
            }
          }
        })
      }
    },

    //查询签了智能运维合同的单位
    getCompany(){
      api.getMindContract(this.companyId).then(res=>{
        if(res.code==10000){
          if(res.data.length!=0){
            this.addCompanyList = res.data
          }else{
            this.addCompanyList = []
          } 
        }
      })
    },
    //获取运维信息列表
    getList(page,limit){
      api.getOpsList({
        companyId:this.companyId,
        devId:sessionStorage.getItem('capID'),
        page:page,
        limit:limit,
      }).then(res=>{
        if(res.code==10000){
          let _data = res.data.list
          this.totalCount = res.data.totalCount
          this.pageSize = res.data.pageSize
          this.currentPage = res.data.currPage
          for(var i in _data){
            _data[i].opsTime = _data[i].opsTime.substring(0,10)
            _data[i].opsType = _data[i].opsType==1?'例行巡检':_data[i].opsType==2?'维修':'保养'
            _data[i].status = _data[i].status==1?'正常':'异常'
            _data[i].opsConstruct = _data[i].opsConstruct=='0'?'企业内部':_data[i].opsConstructName
          }
          this.tableData = _data
        }
      })
    },
    //编辑记录
    handleClickEdit(row){
      this.dialogVisibleEdit = true;
      this.getCompany()
      api.getOpsDetails(row.id).then(res=>{
        if(res.code==10000){
          this.editForm = res.data
          this.opsCompanyChange(res.data.opsConstruct)
        }
      })
    },
    //保存运维信息
    saveOperate(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let _id = sessionStorage.getItem('capID')
          if(_id){
            this.runForm.devId = _id
          }else{
            this.$message.error('请选择设备');
            return;
          }
          this.runForm.companyId = this.companyId
          api.saveOpsMsg(this.runForm).then(res=>{
            if(res.code==10000){
              this.$message.success('保存成功');
              this.getList(this.currentPage,this.pageSize)
              this.saveLoading = false;
              this.dialogVisible = false;
            }else{
              this.$message.error(res.message);
              this.saveLoading = false;
              return;
            }
          })
        } else {
          return false;
        }
      });
    },
    //修改运维信息
    updataOperate(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editLoading = true;
          api.updateOpsDetails(this.editForm).then(res=>{
            if(res.code==10000){
              this.$message.success('修改成功');
              this.getList(this.currentPage,this.pageSize)
              this.editLoading = false;
              this.dialogVisibleEdit = false;
            }else{
              this.$message.error(res.message);
              this.editLoading = false;
              return;
            }
          })
        } else {
          return false;
        }
      });
    },
    //删除记录
    handleClickDel(row){
      this.$confirm('是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.delOpsDetails([row.id]).then(res=>{
          if(res.code==10000){
            this.$message.success('删除成功');
            this.getList(this.currentPage,this.pageSize)
          }else{
            this.$message.error(res.message);
            return;
          }
        })
      })
    }
  },
  mounted(){
    if(sessionStorage.getItem('capID')){
      // this.permissions = JSON.parse(localStorage.getItem('permissions'))
      // if(this.permissions.includes('custom:device:list')){
        this.getList(this.currentPage,this.pageSize)
      // }else{
      //   this.$message.error('没有权限');
      //   return;
      // }
    }
  },
}
</script>
<style lang="less" scoped>
.production{ height: 100%; flex: 1; display: flex; flex-direction: column; overflow: hidden;
  .search{ border: none; padding-top: 0;}
  .tablebox{ height: 100%; flex: 1; overflow-y: auto;}
  .pagebox{ text-align: center; padding-top:10px;}
  .el-select{ width: 100%;}
}
</style>
