<!-- 人员信息 -->
<template>
  <el-dialog v-dialogDrag title="人员信息" :visible.sync="dialogVisible" width="50%">
    <div class="usermessage">
      <div class="subtitle">基本信息</div>
      <el-row :gutter="30">
        <el-form label-width="200px">
          <el-col :span="8">
            <el-form-item label="部门名称">{{formData.deptName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="角色">{{formData.roleName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="员工姓名">{{formData.name}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属公司">{{formData.companyName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话">{{formData.mobile}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱">{{formData.email}}</el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="subtitle" style="margin-top:3rem">关联工单</div>
      <p v-if="!formData.sheetDtos" style="text-align: center;
        font-size: 1.2em;
        padding: 1.2em;" >暂无关联工单</p>
      
      <el-row v-for="(e,i) in formData.sheetDtos" :key="i" :gutter="30" style="margin-bottom:1rem">
        <el-form label-width="200px">
          <el-col :span="8">
            <el-form-item label="工单编号">{{e.sheetNum}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划施工时间">{{e.buildTime}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划竣工时间">{{e.completedTime}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工单负责人">{{e.chargeUser}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="参与人">{{e.constructionMember}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工单状态">{{states.find(item=>item.id==e.status).text}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="概要说明">{{e.essentials}}</el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      formData:{},
      states: [
        { id: 0, text: '待分配', num: 0 },
        { id: 1, text: '待接单', num: 0 },
        { id: 2, text: '已接单', num: 0 },
        { id: 3, text: '施工中', num: 0 },
        { id: 4, text: '拒绝接单', num: 0 },
        { id: 5, text: '申请延期', num: 0 },
        { id: 6, text: '延期成功', num: 0 },
        { id: 7, text: '拒绝延期', num: 0 },
        { id: 8, text: '逾期', num: 0 },
        { id: 9, text: '待审核', num: 0 },
        { id: 10, text: '待评价', num: 0 },
        { id: 11, text: '审核不通过', num: 0 },
        { id: 12, text: '已完成', num: 0 },
        { id: 13, text: '申请取消', num: 0 },
        { id: 14, text: '取消成功', num: 0 },
      ],



    }
  },

  mounted() {

  },

  methods: {
    opendialog(item){
      this.dialogVisible=true
      this.formData = item
    }
  },
}
</script>
<style lang='less' scoped>
.usermessage {
  width: 100%;
  height: 100%;
  height: 60rem;
  overflow: hidden;
  overflow-y: auto;
  .el-form-item {
    margin-bottom: 4px;
    border-bottom: rgba(0, 255, 240, 0.2) solid 1px;
    .el-form-item__label {
      text-align: center;
      padding: 0;
    }
    .el-form-item__content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>