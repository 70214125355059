<template>
    <div class="working">
      <div class="topbox">
        <div class="text">
          <p><label>当前状态</label><b>{{nowStatus}}</b></p>
          <p><label>状态时间</label><b>{{openTime}}</b></p>
          <p><label>状态时长</label><b>{{runTime}}</b></p>
        </div>
        <div style="position:absolute;right:3%">日期：{{nowDay}}</div>
        <div class="echarts" id="line1"></div>
      </div>
      <!-- <div class="search">
        <div class="pull_left"></div>
        <div class="pull_right">
          <el-button size="small" :loading="daochuLoading" @click="daochu" type="info" icon="el-icon-s-promotion">导出</el-button>
        </div>
      </div> -->
      <div class="tablebox">
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column align="center" prop="eventTime" label="时间"></el-table-column>
          <el-table-column align="center" prop="eventDesc" label="事件"></el-table-column>
        </el-table>
        <div style="
            text-align:center;
            position: absolute;
            bottom: 1%;
            right: 48%;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currPage"
            :page-size="10"
            layout="total,prev, pager, next"
            :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
</template>
<script>
let echarts = require("echarts");
import api from '@/apis/device'

export default {
  data() {
    return {
      tableData:[],
      //分页
      currPage:1,
      totalCount:0,
      //数据
      nowStatus:'- -',
      openTime:'- -',
      runTime:'- -',

      daochuLoading:false,
      nowDay:'- -',//折线图数据时间
      // permissions:[],//权限
    }
  },
  methods:{
    //导出数据按钮
    daochu() {
      this.daochuLoading = true
      this._getBookExport()
    },
    //导出数据
    _getBookExport() {
      let _id = sessionStorage.getItem('capID')
      api.getRunIngList({
        id:_id,
        page:1,
        limit:10000,
      }).then(res=>{
        if (res.code == 10000) {
          this.exportList = res.data;
          if(res.data.list.length!=0){
            this.export2Excel();
          }else{
            this.$message.error('无数据可导出')
            this.daochuLoading = false
            return;
          }
        }else{
          this.$message.error(res.message);
          this.daochuLoading = false
          return;
        }
      })
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/Export2Excel");
        const tHeader = [
          "时间",
          "事件",
        ];
        const filterVal = [
          "eventTime",
          "eventDesc",
        ];
        const list = this.exportList;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "运行记录");
        this.daochuLoading = false
      });
    },
    //遍历
    formatJson(filterVal, jsonData) {
      return jsonData.list.map((v) => filterVal.map((j) => v[j]));
    },

    //获取运行记录下面部分列表
    getDownList(cid){
      api.getRunIngList({
        id:cid,
        page:this.currPage,
        limit:10,
      }).then(res=>{
        if(res.code==10000){
          let _data = res.data.list
          this.totalCount = res.data.totalCount
          if(_data.length!=0){
            this.tableData = _data
          }else{
            this.tableData = []
          }
        }
      })
    },
    //获取运行记录上面部分状态
    getTopDetails(cid){
      api.getProdoctDeviceStatus({
        id:cid,
      }).then(res=>{
        if(res.code==10000){
          var nowTime;
          if(res.data){
            let _time = res.data.betweenSecond
            if(_time<60){
              nowTime = _time+'秒'
            }
            if(60<_time<3600){
              nowTime = Math.floor(_time/60)+'分'+Math.floor(_time%60)%60+'秒'
            }
            if(3600<_time<86400){
              nowTime = Math.floor(_time/3600)+'时'+Math.floor((_time%3600)/60)+'分'+Math.floor(_time%3600)%60+'秒'
            }
            if(_time>86400){
              nowTime = Math.floor(_time/86400)+'天'+Math.floor((_time%86400)/3600)+'时'+Math.floor((_time%86400)/60)%60+'分'+Math.floor(_time%86400)%60+'秒'
            }
            this.nowStatus = res.data.currentStatus
            this.openTime = res.data.eventTime
            this.runTime = nowTime
          }
        }
      })
    },
    //获取运行记录上面部分折线图数据
    getTopDetailsLine(cid){
      api.getProdoctDeviceData({
        id:cid,
      }).then(res=>{
        if(res.code==10000){
          if(res.data){
            let _data = res.data.data
            if(_data){
              this.nowDay = _data[0].time.substring(0,10)
              let _time = [];
              let _gl = [];
              let _all = {
                low:res.data.low,
                height:res.data.height,
                powerStandby:res.data.powerStandby,
                tip:res.data.tip,
              }
              for(var i in _data){
                _time.push((_data[i].time).substring(10))
                _gl.push((_data[i].gl).toFixed(2))
              }
              this.line(_time,_gl,_all)
            }else{
              let _all = {
                powerStandby:10,
                low:5,
                height:15,
                tip:20,
              }
              this.line([],[],_all)
            }  
          }else{
            let _all = {
              powerStandby:10,
              low:5,
              height:15,
              tip:20,
            }
            this.line([],[],_all)   
          }
        }
      })
    },
    //分页
    handleCurrentChange(val) {
      let _id = sessionStorage.getItem('capID')
      this.currPage = val
      this.getDownList(_id)//获取运行记录下面部分列表
    },
    line(a,b,c){
      let line = echarts.init( document.getElementById('line1') );
      line.clear()
      line.setOption({
          color:['#00ffcc','#ffff00'],
          title: {
            show: a.length==0,
            textStyle: {
              color: "#fff",
              fontSize: 20,
            },
            text: "暂无数据",
            left: "center",
            top: "40%",
          },
          tooltip: { trigger: 'axis',textStyle:{fontSize:10},
            axisPointer: { type: 'shadow' }
          },
          xAxis: {
              type: 'category',
              axisLabel:{color:'#ccd0f2',fontSize:10},
              axisLine:{lineStyle:{color:'#005ae4'}},axisTick:{ show:false},
              data: a
          },
          yAxis: {
              max:Math.max(...b)>c.tip?Math.max(...b):c.tip,
              axisLabel:{show:false},
              axisLine:{lineStyle:{color:'#005ae4'}},
              splitLine:{show:false},axisTick:{ show:false},
          },
          grid: { left: '15px',right:'10px',top:'20px',bottom:'30px'},
          series: [{
              type: 'line',
              data:b,
              markLine: {
                  data: [
                      {
                          type: 'average', yAxis: c.tip, symbol: 'none',
                          label: {
                              position: 'middle', formatter: '超载功率'+'('+c.tip+')',fontSize:12
                          },
                          lineStyle:{ color:'#ee6666' }
                      },
                      {
                          type: 'average', yAxis: c.height, symbol: 'none',
                          label: {
                              position: 'middle', formatter: '高速功率'+'('+c.height+')',fontSize:12
                          },
                          lineStyle:{ color:'#fac858' }
                      },
                      {
                          type: 'average', yAxis: c.low, symbol: 'none',
                          label: {
                              position: 'middle', formatter: '低速功率'+'('+c.low+')',fontSize:12
                          },
                          lineStyle:{ color:'#5ac12b' }
                      },
                      {
                          type: 'average', yAxis: c.powerStandby, symbol: 'none',
                          label: {
                              position: 'middle', formatter: '待机功率'+'('+c.powerStandby+')',fontSize:12
                          },
                          lineStyle:{ color:'#5ac12b' }
                      }
                  ]
              }
          }] 
      })
    },
  },
  mounted() {
    if(sessionStorage.getItem('capID')){
      let _id = sessionStorage.getItem('capID')
      // this.permissions = JSON.parse(localStorage.getItem('permissions'))
      // if(this.permissions.includes('custom:device:list')){
        this.getDownList(_id)//获取运行记录下面部分列表
        this.getTopDetails(_id)//获取运行记录上面部分详情
        this.getTopDetailsLine(_id)//获取运行记录上面部分折线图
      // }else{
      //   this.$message.error('没有权限');
      //   return;
      // }
    }else{
      this.$message.warning('请选择设备');
      return;
    }
  },
}
</script>
<style lang="less" scoped>
.working{ height: 100%; flex: 1; display: flex; flex-direction: column; overflow: hidden;
  .topbox{ height: 14rem; overflow: hidden; display: flex; flex-direction: row;
    .text{ width: 20rem; flex-shrink: 0; height: 13rem; border: #005ae4 dashed 1px; border-radius: 5px; background: #1b1e84; padding-left: 50px;
      p{ margin:16px 0;}
      b{ font-size: 1.2rem; font-weight: bold; color: #00ffcc;}
      label{ display: block; margin-bottom: 4px;}
    }
    .echarts{ flex: 1; height: 100%;}
  }
  .search{ border: none;}
  .tablebox{ 
    height: 100%; 
    flex: 1; 
    overflow-y: auto;
    position: relative;
  }
}
</style>
<style lang="less">
  .tablebox{ 
    .el-pagination button:disabled{
      background: transparent;
    }
    .el-pagination .btn-next, .el-pagination .btn-prev{
      background: transparent;
    }
    .el-dialog, .el-pager li{
      background: transparent;
    }
  }
</style>
