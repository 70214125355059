import axios from 'axios';
//删除文件，把文件的真实路径地址传过来
const del= (data) => axios.delete('/oos/del', data);

//上传文件
const upload = (data) => axios.post('/oos/upload', data);
//富文本上传
const upload2 = (data) => axios.post('/oos/upload2', data);

export default {
  del,
  upload,
  upload2,
}