<!-- 产品设定 -->
<template>
  <el-dialog v-dialogDrag title="产品设定" :visible.sync="chanpDialog" width="70%" :close-on-click-modal="false" class="adddata">
    <div class="content">
      <div class="mytable">
        <el-form label-width="120px">
          <table style="width: 100%;">
            <thead>
              <tr>
                <td>产品编号</td>
                <td>产品名称</td>
                <td>计量方式</td>
                <td>单位</td>
                <td>单价(万元)</td>
                <td>能耗 先进值</td>
                <td>能耗 准入值</td>
                <td>能耗 限定值</td>
                <td width=50 style="text-align: center;"> <i @click="jianchanpins()"  class="bticon el-icon-circle-plus f_green"></i></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in chanpins" :key='i'>
                <td>
                  <el-input disabled v-model="item.proNum" placeholder="自动生成编号无需输入"></el-input>
                </td>
                <td>
                  <el-input v-model="item.proName" placeholder="请输入产品名称"></el-input>
                </td>

                <td>
                  <el-select v-model="item.metering" filterable placeholder="请选择计量方式">
                    <el-option v-for="item in jl_types" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </td>
                <td>
                  <el-input v-model="item.unit" placeholder="请输入单位"></el-input>
                </td>
                <td>
                  <el-input v-model="item.proPrice" placeholder="请输入单价"></el-input>
                </td>
                <td>
                  <el-input v-model="item.advancedValue" placeholder="请输入"></el-input>
                </td>
                <td>
                  <el-input v-model="item.admittanceValue" placeholder="请输入"></el-input>
                </td>
                <td>
                  <el-input v-model="item.restrictValue" placeholder="请输入"></el-input>
                </td>
                <td style="text-align: center;"><i @click="jiachanpins(i,'产品')"  class="bticon el-icon-remove f_red"></i></td>
              </tr>
            </tbody>
          </table>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" @click="chanpDialog=false;">取 消</el-button>
      <el-button size="medium" @click="saveProduct" type="primary">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/apis/DataEntry'

export default {

  components: {},
  data() {
    return {
      jl_types: [
        { id: 0, name: '数量' },
        { id: 1, name: '长度' },
        { id: 2, name: '面积' },
        { id: 3, name: '体积' },
        { id: 4, name: '容量' },
        { id: 5, name: '质量' },
        { id: 6, name: '包装单位' },
      ],

      //产品
      chanpDialog: false,
      chanpins: [
        // {
        // proNum: '', //编号
        // proName: '', //名称
        // metering: '', //计量方式
        // proPrice: '', //单价
        // unit: '', //单位
        // advancedValue: '', // 先进值
        // admittanceValue: '', // 准入值
        // restrictValue: '', //限定值
        // }
      ],

    }
  },

  mounted() {
    this.getproductList()
  },

  methods: {
    opendialog(){
      this.chanpDialog=true
    },
    //产品设定
    jianchanpins() {
      //添加一行产品
      this.chanpins.unshift({
        proNum: '', //编号
        proName: '', //名称
        metering: '', //计量方式
        proPrice: '', //单价
        unit: '', //单位
        advancedValue: '', // 先进值
        admittanceValue: '', // 准入值
        restrictValue: '', //限定值        
      })
    },
    jiachanpins(index, type) {
      //清除一行产品
      // this.chanpins.splice(index,1)
      let deleteRow = this.chanpins[index]
      this.deletetype = type

      // //判断需不需要发送删除请求
      if (deleteRow.id === undefined) {
        this.chanpins.splice(index, 1)
      } else {
        //检查是否有数据未保存
        for (const iterator of this.chanpins) {
          if (iterator.id === undefined) {
            return alert('有新增未保存数据,请先保存数据再删除！')
          }
        }
        //确认删除提醒
        this.$confirm('确认删除？ 不可恢复！', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            api
              .productDel({
                params: {
                  id: deleteRow.id,
                },
              })
              .then((res) => {
                this.getproductList() //刷新
                if (res.code === 10000) {
                  this.$message.success(res.message)
                } else {
                  this.$message.error(res.message)
                }
              })
          })
          .catch(() => {})
      }
    },
    getproductList() {
      //产品设定列表
      api.productList().then((res) => {
        if (res.code == 10000) {
          this.chanpins = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },    
    //添加修改产品
    saveProduct() {
      var proNames = []
      var proNames2 = []
      this.chanpins.forEach((elem) => {
        proNames.push(elem.proNum)
      })
      proNames2 = Array.from(new Set(proNames))
      if (proNames.length != proNames2.length) {
        alert('产品编号不能重复！')
        return
      }

      api.saveProduct(this.chanpins).then((res) => {
        // console.log(res)
        if (res.code === 10000) {
          this.chanpDialog = false //关闭窗口
          this.getproductList() //重新产品列表
          this.$message({
            message: '保存数据成功！',
            type: 'success',
            duration: '2000',
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },

  },
}
</script>
<style lang='less' scoped>

</style>