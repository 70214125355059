<template>
  <div class="production">
    <!-- <div class="search">
      <div class="pull_left"></div>
      <div class="pull_right">
        <el-button type="success" icon="el-icon-plus" @click="dialogVisible=true">添加关联表计</el-button>
      </div>
    </div> -->
    <div class="tablebox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="id" label="表计编号"></el-table-column>
        <el-table-column prop="type" label="表计类型"></el-table-column>
        <el-table-column prop="name" label="表计名称"></el-table-column>
      </el-table>
    </div>
    </div>
</template>
<script>
import api from '@/apis/device'

export default {
  data() {
    return {
      tableData:[],//表格数据
      assWatch: [
        { name: "电参量", type: "collector_ep" },
        { name: "电气火灾", type: "collector_ef" },
        { name: "电参量+电气火灾", type: "collector_epf" },
        { name: "水表", type: "collector_wp" },
        { name: "气表", type: "collector_gas" },
        { name: "环境采集", type: "collector_env" },
      ],
      permissions:[],//权限
    }
  },
  methods:{
    //获取表计详情
    getList(id){
      api.getRelationList(id).then(res=>{
        if(res.code==10000){
          if(res.data.length!=0){
            let _data = res.data[0].devList
            let _arr = _data.map(item=>{
              for(var i in this.assWatch){
                if(this.assWatch[i].type==item.devType){
                  item.devType = this.assWatch[i].name
                }
              }
              return{
                type:item.devType,
                id:item.devId,
                name:item.devName,
              }
            })
            this.tableData = _arr
          }
        }else{
          this.$message.error(res.message);
          return;
        }
      })
    },
  },
  mounted(){
    if(sessionStorage.getItem('capID')){
      // this.permissions = JSON.parse(localStorage.getItem('permissions'))
      // if(this.permissions.includes('custom:device:list')){
        this.getList(sessionStorage.getItem('capID'))
      // }else{
      //   this.$message.error('没有权限');
      //   return;
      // }
    }
  },
}
</script>
<style lang="less" scoped>
.production{ height: 100%; flex: 1; display: flex; flex-direction: column; overflow: hidden;
  .search{ border: none; padding-top: 0;}
  .tablebox{ height: 100%; flex: 1; overflow-y: auto;}
  .pagebox{ text-align: center; padding-top:10px;}
  .el-select{ width: 100%;}
}
</style>
