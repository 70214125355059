<!-- 报警信息 -->
<template>
  <div>
    <el-dialog v-dialogDrag title="异常信息" :visible.sync="dialogVisible" width="50%">
      <div class="abnomal">
        <div class="tree">
          <!-- 列表 -->
          <li @click="getinfo(e.id);activeListId=e.id" v-for="(e,i) in alarmList" :key="i" :class="activeListId==e.id?'active':''"><span>{{e.subDeviceName}}</span><label>{{e.description}}</label><em>{{e.alarmLevel}}</em></li>
        </div>
        <div class="left" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <div class="information">
            <div class="title"><b>{{formData.companyName}}</b>
              <p>关联区域/设备:{{formData.area}}、{{formData.subDeviceName}}</p>
            </div>
            <div class="type"> <b>{{formData.alarmLevel}}级</b> {{formData.description}}</div>
            <div class="time">
              <p>{{formData.alarmNum}}</p>
              <p>{{formData.alarmTime}}</p>
            </div>
            <div v-if="formData.handleStatus=='0'" class="state red">未确认</div>
            <div v-else-if="formData.handleStatus=='1'" class="state blue">已确认</div>
            <div v-else-if="formData.handleStatus=='2'" class="state orange">已派单</div>
            <div v-else-if="formData.handleStatus=='3'" class="state green">已处理</div>
            <div v-else-if="formData.handleStatus=='4'" class="state hui">自动恢复</div>
          </div>
          <el-form ref="form" :model="formData" label-width="200px">
            <el-row :gutter="30">
              <el-col :span="24">
                <el-form-item label="报警信息" class="myitem">
                  <p>基准数据：{{formData.alarmSetting}}</p>
                  <p>异常数据：{{formData.alarmData}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="企业负责人">{{formData.linkUser}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="联系电话">{{formData.linkTel}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="通知对象">{{formData.username}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="通知方式">{{formData.pushType}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="报警时间">{{formData.alarmTime}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="恢复时间">{{formData.recoverTime}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="处理方式">{{formData.handleWay?formData.handleWay:'未知/未处理'}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="工单编号">{{formData.worksheetNum?formData.worksheetNum:'未派单'}}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="建议处理方案" class="myitem">{{formData.jianyicl}}</el-form-item>
              </el-col>
              <!-- <el-col :span="24">
              <Amap v-show="mapshow" v-loading="!mapload" element-loading-text="地图加载中"
               element-loading-spinner="el-icon-loading" element-loading-background="rgba(245, 245, 235, 0.8)"
                @amapDone="amapDone" 
              style="width: 100%;height: 100%;min-height: 14rem;" ref="Amap"></Amap>
            </el-col> -->
            </el-row>

          </el-form>

        </div>
        <div class="right">
          <h1 class="log">报警日志</h1>
          <el-timeline>
            <el-timeline-item v-for="(item, index) in alarmlog" :key="index" size="large">
              <p>{{item.createTime}}</p>
              <span>{{item.name}}{{item.operation}}</span>
            </el-timeline-item>
          </el-timeline>
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAlarm(formData.id,1)" v-if="formData.handleStatus=='0'" type="primary">确认信息</el-button>
        <!-- <el-button @click="addorder(formData)" v-show="formData.handleStatus!=2&&formData.handleStatus!=3" type="success">一键派单</el-button> -->
        <el-button @click="cancelAlarm(formData.id,3)" title='解除声光报警' type="warning">解除报警</el-button>
      </span>
    </el-dialog>
    <!-- 工单 -->
    <el-dialog v-dialogDrag title="创建工单" style="margin-top: 1vh;" width="50%" :visible.sync="createorderdialog" class="createorderdialog" :close-on-click-modal="false">
      <div class="content">
        <el-row :gutter="50">
        <el-form :model="workForm" label-width="220px">
          <!-- <el-col :span="24">
                        <p class="commontitle">施工信息</p>
                    </el-col> -->
          <el-col :span="6">
            <el-form-item label="企业名称">
              <el-input v-model="workForm.qiye" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="运维公司">
              <el-select filterable v-model="workForm.companyStruct" placeholder="请选择" @change="chooseuser($event)">
                <el-option v-for="(item, index) in opscompanys" :key="'b' + index" :title='item.companyName+"|"+item.companyAddress' :label="item.companyName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="工单类别">
              <el-input v-model="workForm.type" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报警编号">
              <el-input v-model="workForm.baojingid" :disabled="true"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="计划施工时间">
              <el-date-picker v-model="workForm.buildTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划竣工时间">
              <el-date-picker v-model="workForm.completedTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="施工负责人">
              <el-select v-model="workForm.user" filterable placeholder="请选择施工负责人">
                <el-option v-for="(item, index) in users" :key="'h' + index" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="施工队成员">
              <el-select v-model="workForm.userids" filterable multiple placeholder="请选择施工队成员">
                <el-option v-for="(item, index) in users" :key="'9' + index" :label="item.name" :value="item.userId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="事项概要">
              <el-input v-model="workForm.essentials" placeholder="请输入事项概要"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="收费">
              <el-radio-group v-model="workForm.radio" >
                <el-radio label="0">免费</el-radio>
                <el-radio label="1">已预收</el-radio>
                <el-radio label="2">暂不收</el-radio>
                <el-radio label="3">现场收费</el-radio>
              </el-radio-group>
              <el-input v-model="workForm.money" v-show="workForm.radio==3" placeholder="请输入金额" class="money"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="携带工具">
              <el-input v-model="workForm.tools" placeholder="请输入需携带的工具"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="携带配件">
              <el-input v-model="workForm.fitting" placeholder="请输入需携带的配件"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="说明">
              <el-input v-model="workForm.explains" type="textarea" placeholder="请详细说明工单需要处理的内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件">
              <el-upload class="upload-demo" action="/" accept=".docx,.doc,.pdf" readonly :http-request="dochange" :before-upload="beforeAvatarUpload" :before-remove="beforeRemoveFj" multiple :limit="fjlimit" :on-exceed="handleExceed" :file-list="tempFjList">
                <el-button size="small" type="primary">上传附件</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传docx,doc,pdf的文件，且不超过20M
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="图片">
              <el-upload action="/" :http-request="updateImg" list-type="picture-card" :on-preview="handlePictureCardPreview" :before-remove="beforeRemovePic" :file-list="showPicList">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="imgdialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-form>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" type="info" plain @click="createorderdialog = false" class="edit">取 消</el-button>
        <el-button size="medium" type="primary" @click="addconinfo" class="edit">保 存</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import api from '@/apis/maxMap/Abnormal'
import upapi from '../../apis/upload'
export default {
  components: {
    // Amap,
  },
  data() {
    return {
      loading: false,
      mapload: false,
      mapshow: false,
      dialogVisible: false,
      formData: {},
      alarmlog: [],
      alarmList: [],
      activeListId: '',
      fangshis: [
        { id: '0', text: '系统推送' },
        { id: '1', text: 'APP推送' },
        { id: '2', text: '邮件提醒' },
        { id: '3', text: '短信提醒' },
        { id: '4', text: '声光报警' },
        // {id:4,text:'电话提醒'},
      ],

      //工单
      createorderdialog:false,
      imgdialogVisible: false,
      workForm: {
        companyStruct: '', //运维公司
        user: '',
        userids: '',
        radio: '0',
        money: '0',
        essentials: '',
      },



      opscompanys: [], ////运维单位

 

      users: [],
      tempFjList: [], //传给后台临时附件列表
      fjlimit: 10,

      temPicList: [], //传给后台的临时图片路径
      showPicList: [], //显示图片的数组
      dialogImageUrl: '',      





    }
  },

  mounted() {
    //获取运维公司列表
    api.findChildCompany({
      params: {
        deptType: 3, //运维单位
      },
    }).then((res) => {
      if (res.code == 10000) {
        this.opscompanys = res.data
        // console.log(res.data)
      } else {
        this.$message.warning(res.message)
      }
    })

  },

  methods: {
    amapDone() {
      // console.log('Amap加载完成')
    },
    opendialog(item) {
      this.dialogVisible = true
      this.formData = {}
      this.activeListId = ''
      // 判断是否对象

      if (item.constructor == Object) {
        this.getinfo(item.id)
        this.getlist(item.companyId)
        this.activeListId = item.id
      } else {
        //公司id
        this.activeListId = ''
        this.getlist(item)
      }
    },
    getinfo(id) {
      this.loading = true
      api
        .findById({
          params: {
            alarmId: id,
          },
        })
        .then((res) => {
          this.loading = false
          if (res.code == 10000) {
            this.formData = res.data.alarm
            //解决方案
            if (res.data.advise) {
              this.$set(this.formData, 'jianyicl', res.data.advise[0].advise)
            }
            //日志
            this.alarmlog = res.data.log
            //联系人及电话
            this.formData.linkUser =
              JSON.parse(res.data.alarm.companyLinkUser) == null
                ? '--'
                : JSON.parse(res.data.alarm.companyLinkUser)[0].name
            this.formData.linkTel =
              JSON.parse(res.data.alarm.companyLinkUser) == null
                ? '--'
                : JSON.parse(res.data.alarm.companyLinkUser)[0].telNumber

            //通知人员

            this.formData.username = ''
            for (const iterator of res.data.user) {
              this.formData.username += iterator.name + '  '
              this.formData.informtype = iterator.pushType.split(',')
            }
            //通知方式

            this.formData.pushType = ''
            if (this.formData.informtype) {
              for (const iterator of this.formData.informtype) {
                this.formData.pushType +=
                  this.fangshis.find((e) => e.id == iterator).text + '  '
              }
            }
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getlist(companyId) {
      api
        .list({
          params: {
            page: 1,
            limit: 100,
            status: 1,
            companyId,
          },
        })
        .then((res) => {
          if (res.code == 10000) {
            this.alarmList = res.data.list
            if (this.activeListId == '') {
              this.getinfo(this.alarmList[0].id)
              this.activeListId = this.alarmList[0].id
            }
          } else {
            this.$message.error(res.message)
          }
        })
    },
    //确认报警信息
    cancelAlarm(id, status) {
      this.$confirm('确认将该报警信息标记为"已确认"吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          api
            .handleAlarmStatus({
              params: { alarmId: id, type: status },
            })
            .then((res) => {
              if (res.code == 10000) {
                this.getinfo(this.formData.id)
                this.$message.success(res.message)
              } else {
                this.$message.error(res.message)
              }
            })
        })
        .catch(() => {})
    },


    //工单
    //获取所有员工列表
    chooseuser(id) {
      this.workForm.user = ''
      this.workForm.userids = ''
      api.selectCompanyUser({
        params: {
          companyStructId: id,
        },
      }).then((data) => {
        if (data.code === 10000) {
          this.users = data.data
        } else {
          this.$message.error(data.message)
        }
        if (this.users.length <= 0) {
          this.$message.warning('该公司没有人员信息！')
        }
      })
    },
    // 上传附件
    dochange(val) {
      // console.log(val)
      let fd = new FormData()
      fd.append('file', val.file)
      upapi.upload(fd).then((res) => {
        // console.log(res)
        if (res.code == 10000) {
          this.$message({
            message: '上传成功',
            type: 'success',
          })
          this.tempFjList.push({
            name: val.file.name,
            url: res.data,
            uid: val.file.uid,
          })
        } else {
          this.$message({
            message: res.message,
            type: 'warning',
          })
        }
      })
    },
    // 上传图片
    updateImg(val) {
      // console.log(val)
      let fd = new FormData()
      fd.append('file', val.file)
      upapi.upload(fd).then((res) => {
        if (res.code == 10000) {
          this.tempUrl = res.data
          this.temPicList.push({
            name: val.file.name,
            temUrl: res.data,
            uid: val.file.uid,
          })
          // showPicList
          // 显示图片
          this.showPicList.push({
            name: val.file.name,
            temUrl: res.data,
            url: res.data,
            uid: val.file.uid,
          })
          this.$message({
            message: res.message,
            type: 'success',
          })
        } else {
          this.$message({
            message: res.message,
            type: 'warning',
          })
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imgdialogVisible = true
    },
    // 删除附件
    beforeRemoveFj(file) {
      // console.log(this.tempFjList)
      return this.$confirm(`确定移除 ${file.name}？`).then(() => {
        // console.log('点击的确定删除附件')

        // console.log(file)
        this.tempFjList.map((item, index) => {
          if (item.uid == file.uid) {
            // 删除
            upapi.del({ params: { filePath: item.url } }).then((res) => {
              if (res.code == 10000) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                })
                this.tempFjList.splice(index, 1)
              } else {
                this.$message({
                  message: res.message,
                  type: 'warning',
                })
              }
            })
          }
        })
      })
    },
    // 删除图片
    beforeRemovePic(file) {
      // console.log(this.showPicList)
      return this.$confirm(`确定移除 ${file.name}？`).then(() => {
        // console.log('点击的确定删除图片')

        // console.log(file)
        this.showPicList.map((item, index) => {
          if (item.uid == file.uid) {
            // 删除
            upapi.del({ params: { filePath: item.temUrl } }).then((res) => {
              if (res.code == 10000) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                })
                this.showPicList.splice(index, 1)
              } else {
                this.$message({
                  message: res.message,
                  type: 'warning',
                })
              }
            })
          }
        })
      })
    },
    handleExceed() {
      this.$message.warning('只能上传' + this.fjlimit + '个附件')
    },
    // 上传判断附件
    beforeAvatarUpload(file) {
      // console.log(file)
      const isDOC =
        file.type === 'application/msword' ||
        file.type === 'application/pdf' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      const isLt20M = file.size / 1024 / 1024 < 20

      if (!isDOC) {
        this.$message.error('上传文件类型只能是 docx/doc/pdf格式!')
      }
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isDOC && isLt20M
    },

    //创建工单弹出框
    addorder(row) {
      this.workForm = {
        companyStruct: '', //运维公司
        user: '',
        userids: '',
        radio: '0',
        money: '0',
        essentials: '',
        explains: '',
      }

      this.createorderdialog = true
      this.workForm.qiye = row.companyName
      this.workForm.type = '报警处理'
      this.workForm.essentials = row.subDeviceName + '>>' + row.description
      this.workForm.explains =
        '异常数据：' + row.alarmData + '\n基准数据：' + row.alarmSetting + '\n'
      this.workForm.expl = row.id
      this.workForm.baojingid = row.alarmNum
      this.workForm.companyId = row.companyId
      // 默认时间
      this.workForm.buildTime = new Date().Format('yyyy-MM-dd hh:mm:ss')
      this.workForm.completedTime = new Date(
        new Date().getTime() + 3 * 24 * 60 * 60 * 1000
      ).Format('yyyy-MM-dd hh:mm:ss')
    },

    //保存工单
    addconinfo() {
      if (this.workForm.companyStruct === '') {
        return this.$message.warning('请选择运维公司！')
      }

      //   this.addconfirm = false;
      var obj = {
        annex: JSON.stringify(this.tempFjList), //上传的临时路径
        buildTime: this.workForm.buildTime,
        charge: parseInt(this.workForm.radio), //收费类型
        chargeAmount: Number(this.workForm.money), //收费金额
        chargeUser: this.workForm.user, //施工负责人
        companyName: this.workForm.qiye,
        completedTime: this.workForm.completedTime,
        constructionMemberId:
          this.workForm.userids != '' ? this.workForm.userids.join() : '', //施工队成员
        essentials: this.workForm.essentials, //事件概要
        explains: this.workForm.explains, //说明
        party: 1, //施工方 1 运营端 2企业端,
        fitting: this.workForm.fitting, //携带的配件

        pictureUrls: JSON.stringify(this.temPicList),
        status: 0, //工单类别提交的状态  0 待分配，1待接单，2，施工种，3，延期，4待审核，5待评价，6已完成，7已取消
        tools: this.workForm.tools,
        type: 3, //工单类型 1:合同新装，2例行巡检 3.报警处理 4.设备维修 5其他
        expl: this.workForm.expl, //根据type选的类型传个自的id
        companyId: this.workForm.companyId,
        companyStruct: this.workForm.companyStruct, //运维公司
      }

      obj.constructionMember = '' //施工成员姓名
      for (var j in this.users) {
        if (this.users[j].name === this.workForm.user) {
          obj.chargeUserId = this.users[j].userId
        }
        for (const iterator of this.workForm.userids) {
          if (iterator === this.users[j].userId) {
            if (obj.constructionMember == '') {
              obj.constructionMember += this.users[j].name
            } else {
              obj.constructionMember += ',' + this.users[j].name
            }
          }
        }
      }
      // console.log(obj);

      api.CreateWorkOrder(obj).then((data) => {
        if (data.code === 10000) {
          this.$message({ message: '创建成功', type: 'success' })
          this.createorderdialog = false
          this.getinfo(this.formData.id)
        } else {
          this.$message({ message: data.message, type: 'warning' })
        }
      })
    },    





  },
}
</script>
<style lang='less' scoped>
.abnomal {

  
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 60rem;



  .el-form-item {
    margin-bottom: 10px;
    border-bottom: rgba(0, 255, 240, 0.2) solid 1px;
    .el-form-item__label {
      text-align: center;
      padding: 0;
    }
    .el-form-item__content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .tree {
    width: 22rem;
    border: #005ae4 solid 1px;
    padding: 0.6rem; overflow-y: auto;
    flex-shrink: 0;
    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.4rem;
      align-items: center;
      height: 2.6rem;
      padding: 0 0.4rem;
      overflow: hidden;
      span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      label {
        flex-shrink: 0;
        margin: 0 0.4rem;
        color: #00ffcc;
        border: #00ffcc dashed 1px;
        border-radius: 5px;
        padding: 0 5px;
      }
      em {
        flex-shrink: 0;
        font-style: normal;
        width: 1.2rem;
        height: 1.2rem;
        line-height: 1.2rem;
        text-align: center;
        color: #000;
        background: #00ccff;
        border-radius: 50%;
      }
      em.red {
        background: #fd605c;
      }
      em.yellow {
        background: #f0904a;
      }
      em.green {
        background: #5ac12b;
      }
      cursor: pointer;
    }
    li:hover,
    li.active {
      background: #117df9;
      color: #fff;
      label {
        color: #fff;
        border-color: #fff;
      }
    }
  }
  .left {
    flex: 1;
    position: relative;
    padding: 10px 2.85em 0px 0.71em;
    .information {
      background: rgba(0, 255, 240, 0.1);
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      height: 4.6rem;
      padding-left: 1rem;
      .title {
        flex: 1;
        display: flex;
        flex-direction: column;
        b {
          font-size: 1.2rem;
        }
        p {
          opacity: 0.8;
          margin-top: 2px;
        }
      }
      .type {
        font-weight: bold;
        color: #f0904a;
      }
      .time {
        margin: 0 30px;
        color: #f0904a;
      }
      .state {
        font-weight: bold;
        padding: 0 1rem;
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .state.green {
        background: #5ac12b;
      }
      .state.orange {
        background: #f0904a;
      }
      .state.blue {
        background: #117df9;
      }
      .state.red {
        background: #fd605c;
      }
      .state.hui {
        background: #999;
      }
    }
  }
  .right { width: 30rem; flex-shrink: 0; padding: 10px; overflow-y: auto;
    /deep/ .el-timeline-item__content {
      color: #f5f5f5;
      font-size: 1.2rem;
    }
    .log {position: relative;font-size: 1.2rem;color: #00ffcc;border-bottom: #00ffcc dotted 2px;padding-bottom: 10px;margin-bottom: 1rem;
    }
  }
}
</style>
<style lang="less">

  .myitem {
    position: relative;
    .el-form-item__label {
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  .createorderdialog{
    .el-input.is-disabled .el-input__inner{
      border:#005ae4 solid 1px; background: rgba(255,255,255,.15); color: #ccc;
    }
    .el-form-item__content{ padding-left: 0;}
    .el-input__inner{ height: 2.5rem !important; border-radius: 0; border:#005ae4 solid 1px; color:#00ccff;}
    .el-select,.el-date-editor.el-input, .el-date-editor.el-input__inner{ width: 100%;}
    .el-input,.el-radio__label{ font-size: 1rem;}
    .el-input__suffix{ right: .6rem;}
    .el-radio{ color:#ccd0f2; font-size: 1rem;}
    .el-radio__inner{ width:1rem; height: 1rem;}
    .el-radio__inner::after{ width: .3rem; height: .3rem;}
    .el-radio__input.is-checked+.el-radio__label{ color: #00ccff;}
    .el-textarea{ font-size: 1rem;}
    .el-textarea__inner{ background: transparent; border: #005ae4 solid 1px; color: #00ccff; height: 6rem;}
    .el-upload__tip{ color: #ccd0f2; font-size: .85rem;}
    .el-upload--picture-card{ border-color: #00ffcc; background: #003f9c;}
    .el-upload--picture-card i{ font-size: 2rem; color: #00ffcc;}
  }
</style>